<template>
    <div class="relative  w-full  flex justify-center items-center px-2   min-h-48">
        <apexchart class=" w-full " type="bar" :options="chartOptions" :series="series" height="100%"
            @updated="onUpdate()" @mounted="onMounted()" :class="{'opacity-0' : !loaded, 'opacity-100': loaded }" />
    </div>
</template>

<style scoped>
    .chart {
        width: 100%;
        /* Prend toute la largeur du parent */
        height: 100%;
        /* Prend toute la hauteur du parent */
        max-width: 100%;
        /* Empêche le débordement horizontal */
        max-height: 100%;
        /* Empêche le débordement vertical */
        display: block;

        min-height: initial !important;
        /* Évite les comportements indésirables d'affichage */
    }

    .relative {
        position: relative;
        /* width: 100%; Occupe toute la largeur */
        /* height: ; Assure la hauteur complète */
        height: 100%;
        /* display: flex;Pour centrer les éléments */
        /* justify-content: center; */
        /* align-items: center; */
        overflow: hidden;
        /* Empêche tout débordement */
        /* Empêche le débordement du contenu */
    }
</style>

<script>

    let unit = null
    import VueApexCharts from "vue3-apexcharts";
    export default {
        name: 'ColumnBarChart',
        components: {
            apexchart: VueApexCharts,
        },
        props: {
            parent: String,
            title: String,
            records: Object,
            period: Object,
            config: Object,
            refresh: String
        },
        data: function () {
            let self = this;

            return {
                myseries: [],
                loaded: false,
                chartOptions: {},
                unit: "",
                configuration: {
                    chart: {
                        type: 'bar',
                        stacked: false,
                        stackType: '100%',
                        height: '100%', // S'assure que le graphique s'adapte à la hauteur de la div
                        width: '100%',
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                                reset: false,
                                customIcons: []
                            },
                            export: {

                            },
                            autoSelected: 'zoom'
                        },
                    },
                    title: {
                        text: this.title
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 1,
                            horizontal: false
                        }
                    },
                    colors: ['#3357FF'],
                    legend: {
                        position: 'top',
                        enabled: false
                    },
                    tooltip: {
                        shared: true,
                        intersect: false, y: {
                            formatter: function (value) {
                                const formattedNumber = new Intl.NumberFormat('fr-FR').format(value);
                                // Personnaliser le format des labels ici
                                return formattedNumber + " " + self.unit; // Par exemple, ajouter un symbole euro
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: function (value) {
                                const formattedNumber = new Intl.NumberFormat('fr-FR').format(value);
                                if (unit != undefined) return formattedNumber + " " + unit;
                                return formattedNumber;
                            }
                        }
                    },
                    xaxis: {
                        //  categories: [],
                        //  axisBorder: {
                        //      offsetX: 13
                        // }
                         //  type: 'datetime',
                        labels: {
                            show: true,
                            /* formatter2: function (value) {
                                 let d = new Date(value).getDate()
                                 let m = new Date(value).getMonth() + 1
                                 return d + "/" + m
                                 //   value = value.toString()
                                 //    return new Date().toISOString()
                                 //     let y = value.substring(0, 4)
                                 //     let m = value.substring(4, 6)
 
                                 //     return m + "/" + y.substring(2, 4);
                             }*/
                        }
                        /*  labels: {
                              show: true,
                              formatter: function (value) {
                                  value = value.toString()
                                  let y = value.substring(0, 4)
                                  let m = value.substring(4, 6)
  
                                  return m + "/" + y.substring(2, 4);
                              }
                          }*/
                    }


                },
                series: [
                    {
                        data: [],
                        name: ""
                    }
                ]
            };
        },
        watch: {
            'refresh': function () {
                console.log("ColmmBarChart", "RowBarChart refresh WATCH records")
                if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
            },

            'records': async function (val) {

                //   let t = this.transformData(val);
                this.unit = this.config?.unit;

                if (this.config?.graph?.color != undefined) {
                    this.chartOptions = { colors: ['#FF5733', '#005733'] }
                }
                console.log("val.series----------------------------------------------")
                console.log(JSON.stringify(val.series))
                this.unit = this.config.unit
                this.series = val.series
                //
                //this.series[0] = { name: this.config.name, data: t }
                //  this.series[0] = { name: this.config.name, data: t }
            },
            'aarecords': async function (val) {

                //console.log("ColumnBarChart WATCH records")
                //   console.log(val)
                unit = this.config?.graph?.unit;
                let t = this.transformData(val);

                let keys = Object.keys(t)


                //        let series = []

                console.log("ColmmBarChart", JSON.stringify(keys))

                for (let i = 0; i < keys.length; i++) {

                    console.log("ColmmBarChart", keys[i], t[keys[i]].data.length)
                    //  this.series[i] = { data: t[keys[i]].data }
                    this.series[i] = { data: t[keys[i]].data }
                    // console.log(i, keys[i], this.series[i].data.length)
                }

                //   console.log(JSON.stringify(this.series[0].data))
                // this.series[0] = series

                /*  for (let i = 0; i < keys.length; i++) {
                      this.series[i] = { name: t[keys[i]].name, data: t[keys[i]].data }
  
                  }*/

                // this.chartOptions =
                // {
                //     xaxis: {
                //         categories: r.categories
                //     }
                //    labels : r.categories
                // }
                //     


            }


        },
        async mounted() {


            if (this.config.chart)
                this.chartOptions = this.mergeDeep(this.configuration, this.config.chart);

            else
                this.chartOptions = this.configuration;

            window.addEventListener("resize", this.updateChartSize);


            if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.parent })

        },
        methods:
        {
            mergeDeep(target, source) {
                for (const key in source) {
                    if (source[key] && typeof source[key] === 'object' && !Array.isArray(source[key])) {
                        // Si la valeur est un objet, appeler récursivement
                        target[key] = this.mergeDeep(target[key] || {}, source[key]);
                    } else {
                        // Sinon, remplacer la valeur
                        target[key] = source[key];
                    }
                }
                return target;
            },
            onUpdate() {
                //    console.log("onUpdate")
                this.loaded = true;
            },
            onMounted() {
                //    console.log("onMounted")
                // this.loaded = true;
            },
            updateChartSize() {
                this.chartOptions.chart = {
                    ...this.chartOptions.chart,
                    height: "100%",
                    width: "100%",
                };
            },
            getNbDays(period) {
                switch (period.type) {
                    case '7days': return 7;
                    case '15days': return 15;
                    case '30days': return 30;
                    case '90days': return 90;
                    case '180days': return 180;
                    case '365days': return 365;
                    case 'custom': return 365;
                }
                return 7;
            },
            transformDataNumber(records) {
                let data = {}
                let color = "color"
                if (data[color] == undefined) {
                    data[color] = { name: color, data: [] }
                }
                for (let i = 0; i < records.records.length; i++) {
                    data[color].data.push({ x: records.records[i].x, y: records.records[i].y })
                }
                return [{ name: this.config.name, data: data }]
            },

            transformData(records) {
                let data = []
                for (let i = 0; i < records.records.length; i++) {
                    data.push({ x: records.records[i].x, y: records.records[i].y })
                }
                let dataz = []
                for (let i = 0; i < records.records2.length; i++) {
                    dataz.push({ x: records.records2[i].x, y: records.records2[i].y })
                }
                return [{ name: this.config.name, data: dataz }, { name: this.config.name, data: data }]
            },


            transformDataDateTime(records) {
                let data = []
                for (let i = 0; i < records.records.length; i++) {
                    let d = records.records[i].x.year + "-" + records.records[i].x.month + "-" + records.records[i].x.date
                    data.push({ x: new Date(d).getTime(), y: records.records[i].y })
                }
                return data
            },

            transformDataOld(records) {
                //   if (this.config.mode == "numeric") return this.transformDataNumber(records)

                console.log("ColumnBarChart", "transformData", "before", records)
                function formatDigit(n) {
                    if (parseInt(n) == 1) return "01"
                    if (parseInt(n) == 2) return "02"
                    if (parseInt(n) == 3) return "03"
                    if (parseInt(n) == 4) return "04"
                    if (parseInt(n) == 5) return "05"
                    if (parseInt(n) == 6) return "06"
                    if (parseInt(n) == 7) return "07"
                    if (parseInt(n) == 8) return "08"
                    if (parseInt(n) == 9) return "09"
                    return n
                }

                let data = {}
                let color = "color"
                //    console.log(" records.records.length", records.records.length)
                // data[color].data = []

                for (let i = 0; i < records.records.length; i++) {

                    if (records.records[i].color != undefined) {
                        color = records.records[i].color
                    }

                    if (data[color] == undefined) {
                        data[color] = { name: records.records[i].name, data: [] }

                        if (this.period.type != "custom") {

                            // By Day
                            let nb_day = this.getNbDays(this.period);
                            //console.log("period", this.period)
                            // console.log("NB Dates", nb_day)

                            for (let i = 0; i < nb_day; i++) {
                                let day = new Date();
                                day.setDate(day.getDate() - i);
                                let sday = day.getFullYear() + "-" + formatDigit(day.getMonth() + 1) + "-" + formatDigit(day.getDate())
                                data[color].data.push({ x: new Date(sday).getTime(), y: 0 })

                            }


                        }
                        else {
                            data[color].data.push({ x: records.records[i].x, y: records.records[i].y })

                            const startDate = new Date(this.period.started_at);
                            const endDate = new Date(this.period.ended_at);


                            for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {

                                let sday = d.getFullYear() + "-" + formatDigit(d.getMonth() + 1) + "-" + formatDigit(d.getDate())
                                data[color].data.push({ x: new Date(sday).getTime(), y: 0 })
                            }


                        }

                    }



                    // console.log("NB Dates 2", data[color].data.length)


                    let date_x = records.records[i].x.year + "-" + formatDigit(records.records[i].x.month + 1) + "-" + formatDigit(records.records[i].x.date)
                    // console.log(date_x)

                    let date_gt = new Date(date_x).getTime()
                    //  console.log(date_gt)
                    //  console.log(data[color].data)

                    let index = data[color].data.findIndex(function (a) { return a.x == date_gt })
                    //  console.log("////", date_x, index)
                    if (index != -1) {
                        //     console.log("FindIndex")
                        data[color].data[index] = {

                            x: new Date(date_x).getTime(),
                            y: records.records[i].y
                        }

                        ////      x: records.records[i].x,//new Date( records.records[i].x).getTime(),

                    } else console.log("NotFindIndex-----------------------------------------", date_x)



                }
                console.log("ColumnBarChart", "transformData", "after", data)
                return data

            }
        }



    }
</script>