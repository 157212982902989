<template>
  <p class="hidden ">Largeur : {{ divDimensions.width }}px</p>
  <p class=" hidden ">Hauteur : {{ divDimensions.height }}px</p>
  <p class="hidden">Scale : {{ divDimensions.scale }} </p>
  <p class="hidden">*{{chartOptions?.plotOptions}}*</p>
  <p class="hidden ">*{{(this.divDimensions.height -100.0) /2}}*</p>



  <div :ref="`semi-donut-${config.id}`" class="relative  w-full    flex justify-center items-center px-2  min-h-36">
    <apexchart class=" w-full   pt-0 bg-orange-100" type="donut" :options="chartOptions" :series="series" height="100%"
      width="100%" @updated="onUpdate()" @mounted="onMounted()"
      :class="{'opacity-0' : !loaded, 'opacity-100': loaded }" />
  </div>

</template>


<style scoped>
  .chart {
    width: 100%;
    /* Prend toute la largeur du parent */
    height: 100%;
    /* Prend toute la hauteur du parent */
    max-width: 100%;
    /* Empêche le débordement horizontal */
    max-height: 100%;
    /* Empêche le débordement vertical */
    display: block;

    min-height: initial !important;
    /* Évite les comportements indésirables d'affichage */
  }

  .relative {
    position: relative;
    /* width: 100%; Occupe toute la largeur */
    /* height: ; Assure la hauteur complète */
    height: 100%;
    /* display: flex;Pour centrer les éléments */
    /* justify-content: center; */
    /* align-items: center; */
    overflow: hidden;
    /* Empêche tout débordement */
    /* Empêche le débordement du contenu */
  }
</style>
<script>
  import VueApexCharts from "vue3-apexcharts";
  export default {
    name: 'SemiDonutChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      parent: String,
      title: String,
      records: Object,
      config: Object,
      refresh: String
    },
    data: function () {
      return {
        categories: [],
        loaded: false,
        ratio: 1.0,
        divDimensions: {
          width: 0,
          height: 0, scale: 1.0
        },
        chartOptions: {},
        configuration: {
          chart: {
            type: 'donut',
            height: '100%',
            width: '100%',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "15px", // Taille de la police
              fontWeight: "bold", // Optionnel : épaisseur de la police
              colors: ["#FFF"], // Optionnel : couleur des textes
            },
            dropShadow: {
              enabled: true,
              left: 2,
              top: 2,
              opacity: 0.5
            }
          },

          legend: {
            position: 'top',
            show: true,
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90,
              /* size: '45%',*/
              customScale: 2.0,
              offsetY: 130,
              /*  */
              donut: {
                size: '45%',
              }, dataLabels: {
                offset: 0, // Évite le repositionnement dynamique
                minAngleToShowLabel: 10, // Affiche les labels pour les segments supérieurs à 10°
              },
            }
          },
          grid2: {
            padding: {
              bottom: -80
            }
          },
          responsive2: [{
            breakpoint: 480,
            options: {
              chart: {
                height: 400
              },
              legend: {
                position: 'right',
                show: false,
              }
            }
          }]


        },
        series: [],
      };
    },
    watch: {
      'refresh': function () {
        console.log("ColmmBarChart", "RowBarChart refresh WATCH records")
        if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      },
      'records': async function (val) {
        console.log("SemiDonuts WATCH records")
        console.log(JSON.stringify(val))
        let r = this.transformData(val);


        if (r == undefined) r = { data: [], name: "" }
        this.localrecords = r

        if (r.series.length == 0) {
          this.series = []
        } else {

          this.series = r.series[0].data;
          //this.series.data = r.series[0].data;
        }





        this.categories = r.categories
        this.updateChartSize();

      }
    },

    async mounted() {

      if (this.config.chart)
        this.chartOptions = this.mergeDeep(this.configuration, this.config.chart);

      else
        this.chartOptions = this.configuration;


      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      window.addEventListener("resize", this.updateChartSize);
      this.updateChartSize();

    },


    beforeUnmount() {
      window.removeEventListener("resize", this.updateChartSize);
    },


    methods: {
      mergeDeep(target, source) {
        for (const key in source) {
          if (source[key] && typeof source[key] === 'object' && !Array.isArray(source[key])) {
            // Si la valeur est un objet, appeler récursivement
            target[key] = this.mergeDeep(target[key] || {}, source[key]);
          } else {
            // Sinon, remplacer la valeur
            target[key] = source[key];
          }
        }
        return target;
      },
      onUpdate() {
        console.log("onUpdate")
        this.loaded = true;
      },
      onMounted() {
        this.updateChartSize();
        console.log("onMounted")
        // this.loaded = true;
      },



      getDivSize() {

        const div = this.$refs["semi-donut-" + this.config.id]; // Référence à la div
        if (div) {
          const rect = div.getBoundingClientRect();
          this.divDimensions = {
            width: rect.width,
            height: rect.height,
            scale: this.chartOptions?.plotOptions?.pie?.customScale
          };
          console.log("Taille de la div:", this.divDimensions);
        }
        //  this.chartOptions.plotOptions.pie.customScale = 1.0
      },
      updateChartSize() {

        this.getDivSize();

        this.chartOptions = {
          labels: this.categories,
          chart: { height: 350 },
          legend: {
            position: 'top',
            show: true,
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90,
              //    customScale: ((this.divDimensions.height + 250 )/ (this.divDimensions.height  )),
              //   offsetY: (this.divDimensions.height) / 1.5,
              donut: {
                size: '25%',
              }
            },
          },
          dataLabels: {
            style: {
              fontSize: "36px", // Taille de la police
              fontWeight: "italic", // Optionnel : épaisseur de la police
              colors: ["#FFF"], // Optionnel : couleur des textes
            }
          }
        }
      },

      transformData(data) {



        if (data.records.length > 0) {


          return { categories: data.records[0].categories, series: [{ name: "", data: data.records[0].data }] }
        }
        return { categories: [], series: [] }
      }
    },

  }
</script>