<template>
  <div
    class="hidden relative w-full sm:min-h-12 xl:min-h-16 2xl:min-h-24  flex justify-center items-center p-2 flex-col ">
    <div>
      <div class="text-3xl sm:text-3xl lg:text-5xl xl:text-5xl 2xl:text-5xl 2xl:bg-red-600" :class="class_counter">
        {{ counter }}
      </div>

      <div class="text-gray-300 lg:text-xl" :class="class_unit">
        {{ config?.unit }}
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
        class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    </div>
  </div>

  <div v-if="config.template == undefined" class=" w-full flex flex-col justify-start justify-between h-full ">
    <div class="w-full flex flex-col justify-start">
      <p class="text-gray-500 text-base text-left"> {{ config?.title }} </p>
    </div>
    <div class="w-full flex flex-col justify-start">
      <div class="w-full flex flex-row justify-start">


        <div class="mt-4 pr-2 text-xl font-semibold text-gray-400  text-left flex flex-row ">

          {{config?.unit}}
        </div>
        <h1 class="mt-4 text-4xl 2xl:text-5xl font-semibold text-gray-900  text-left flex flex-row ">

          {{counter}}
        </h1>


      </div>

      <div class="flex items-center space-x-1  mt-2">

        <i class="fa-regular  "
          :class="{'test-red-500 fa-chart-line-up-down ' : (trend =='down'),'text-green-500 fa-chart-line-up ' : (trend =='up')}"></i>
        <span class=" text-base  "
          :class="{'test-red-500' : (trend =='down'),'text-green-500' : (trend =='up')}">{{percent}}%</span>


      </div>
      <p class="text-gray-400 text-sm mt-1 text-left"> {{ config?.subtitle }} </p>
    </div>
  </div>
  <div v-if="config.template == 'tpl-2'" class="    w-full flex flex-col justify-start justify-between h-full ">
    <div class="w-full flex flex-col justify-start">
      <p class="text-gray-300 text-3xl text-left font-semibold"> {{ config?.title }} </p>
    </div>
    <div class="w-full flex flex-col justify-start">
      <div class="w-full flex flex-row justify-start">


        <div class="mt-4 pr-2 text-xl font-semibold text-gray-200  text-left flex flex-row ">

          {{config?.unit}}
        </div>
        <h1 class="mt-4 text-7xl font-semibold text-gray-100  text-left flex flex-row ">

          {{counter}}
        </h1>
      </div>

      <p class="mt-4 text-gray-200 text-sm mt-1 text-left"> {{ config?.subtitle }} </p>
    </div>
  </div>

  <div v-if="config.template == 'tpl-3'" class="    w-full flex flex-col justify-start justify-between h-full ">
    <div class="w-full flex flex-col justify-start">
      <p class="text-gray-300 text-2xl text-left font-semibold"> {{ config?.title }} </p>
    </div>
    <div class="w-full flex flex-col justify-start">
      <div class="w-full flex flex-row justify-center">


        <div class="mt-4 pr-2 text-xl font-semibold text-gray-300  text-left flex flex-row ">

          {{config?.unit}}
        </div>
        <h1 class="mt-4 text-6xl font-semibold text-gray-100  text-left flex flex-row ">

          {{counter}}
        </h1>
      </div>
      <div class="w-full flex flex-col justify-start   mt-9">
        <div class="w-full flex flex-row justify-start items-center">
          <span class="text-left text-gray-100  w-6/12 text-sm flex-1">Nombre Individus</span>
          <span class="text-right text-gray-100  w-6/12 text-sm">{{count}}</span>

        </div>
        <div class="w-full flex flex-row justify-start items-center" v-if="config?.options?.display_min">
          <span class="text-left text-gray-100  w-6/12 text-sm flex-1">Poids Mini</span>
          <span class="text-right text-gray-100   w-6/12 text-sm">{{min}} kg</span>

        </div>
        <div class="w-full flex flex-row justify-start items-center" v-if="config?.options?.display_max">
          <span class="text-left text-gray-100  w-6/12 text-sm flex-1">Poids Maxi</span>
          <span class="text-right text-gray-100  w-6/12 text-sm">{{max}} kg</span>
        </div>
        <div class="w-full flex flex-row justify-start items-center" v-if="config?.options?.display_count_2">
          <span class="text-left text-gray-100  w-6/12 text-sm flex-1">Nombre de Saisies</span>
          <span class="text-right text-gray-100  w-6/12 text-sm">{{sum_ko}}</span>
        </div>

      </div>

      <p class="mt-2 text-gray-200 text-sm mt-1 text-left"> {{ config?.subtitle }} </p>
    </div>
  </div>


</template>

<script>

  export default {
    name: 'CounterChart',
    components: {},
    props: {
      parent: String,

      refresh: String,
      records: Object,
      config: Object
    },
    data: function () {
      return {
        unit: "",
        decimal: 0,
        min: "",
        max: "",
        count: "",
        sum_ko: "",
        counter: "",
        trend: "up",
        percent: "-"
      };
    },
    computed:
    {
      class_counter: function () {
        if (this.config?.class_counter) return this.config.class_counter;
        return {}
      },
      class_unit: function () {
        if (this.config?.class_unit) return this.config.class_unit;
        return {}
      }
    },
    watch: {
      'refresh': function () {
        if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      },

      'records': async function (val) {
        // alert("records counter chart")
        // console.log("watch Counter records vvvvvvvvvv")
        // console.log(JSON.stringify(val))
        //   console.log("Counter records AAAAAAAA")

        this.decimal = this.config?.decimal ? this.config?.decimal : 0

        if (val != undefined) {
          if (val.records != undefined) {
            if (val.records.length > 0) {


              if (val.records[0].count != undefined) this.count = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(val.records[0].count);
              if (val.records[0].min != undefined) this.min = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(val.records[0].min);
              if (val.records[0].max != undefined) this.max = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(val.records[0].max);

              if (val.records[0].sum_ko != undefined) this.sum_ko = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(val.records[0].sum_ko);


              if (this.config.operator == "amount") {
                if (val.records[0].amount != undefined)
                  this.counter = new Intl.NumberFormat('fr-FR', {
                    minimumFractionDigits: this.decimal,
                    maximumFractionDigits: this.decimal
                  }).format(val.records[0].amount);
              }
              else if (this.config.operator == "avg") {
                if (val.records[0].avg != undefined)
                  this.counter = new Intl.NumberFormat('fr-FR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(val.records[0].avg);
              } else {
                if (val.records[0].amount != undefined)
                  this.counter = new Intl.NumberFormat('fr-FR', {
                    minimumFractionDigits: this.decimal,
                    maximumFractionDigits: this.decimal
                  }).format(val.records[0].amount);
              }
            }
            else {
              this.counter = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: this.decimal,
                maximumFractionDigits: this.decimal
              }).format(0);


      
              this.count = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: this.decimal,
                maximumFractionDigits: this.decimal
              }).format(0);

  

              this.min = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: this.decimal,
                maximumFractionDigits: this.decimal
              }).format(0);

        
              this.max = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: this.decimal,
                maximumFractionDigits: this.decimal
              }).format(0);

         
              this.sum_ko = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: this.decimal,
                maximumFractionDigits: this.decimal
              }).format(0);
            }
          }
        }
        //let r = this.transformData(val);
        // if (r == undefined) r = { data: [], name: "" }
        // this.localrecords = r
        // this.series.data = r.series[0].data;

        //     this.series[0].name = r.series[0].name;
      }
    },


    async mounted() {

      //   this.chartOptions.subtitle.text = this.config?.title + '**mounted*title*';
      //    this.chartOptions.title.text = this.value + '**mounted*value*';
      //console.log("FigureSparklineChart mounted")
      //this.localrecords = sparklineData
      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })


    },
    methods: {

    }
  }
</script>