<template>
  <p class="hidden">Largeur : {{ divDimensions.width }}px</p>
  <p class="hidden ">Hauteur : {{ divDimensions.height }}px</p>
  <p class="hidden">Scale : {{ divDimensions.scale }} </p>
  <p class="hidden">*{{chartOptions?.plotOptions}}*</p>
  <p class="hidden ">*{{(this.divDimensions.height -100.0) /2}}*</p>


  <div :ref="`pie-${config.id}`"
    class="relative  w-full bg-orange-100  flex justify-center items-center px-2  min-h-36">
    <apexchart class=" w-full   pt-0" type="pie" :options="chartOptions" :series="series" height="100%" width="100%"
      @updated="onUpdate()" @mounted="onMounted()" :class="{'opacity-100' : !loaded, 'opacity-100': loaded }" />
  </div>

</template>


<style scoped>
  .chart {
    width: 100%;
    /* Prend toute la largeur du parent */
    height: 100%;
    /* Prend toute la hauteur du parent */
    max-width: 100%;
    /* Empêche le débordement horizontal */
    max-height: 100%;
    /* Empêche le débordement vertical */
    display: block;

    min-height: initial !important;
    /* Évite les comportements indésirables d'affichage */
  }

  .relative {
    position: relative;
    /* width: 100%; Occupe toute la largeur */
    /* height: ; Assure la hauteur complète */
    height: 100%;
    /* display: flex;Pour centrer les éléments */
    /* justify-content: center; */
    /* align-items: center; */
    overflow: hidden;
    /* Empêche tout débordement */
    /* Empêche le débordement du contenu */
  }
</style>
<script>
  import VueApexCharts from "vue3-apexcharts";
  export default {
    name: 'PieChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      parent: String,
      title: String,
      records: Object,
      config: Object
    },
    data: function () {
      return {
        loaded: false,
        ratio: 1.0,
        divDimensions: {
          width: 0,
          height: 0, scale: 1.0
        },
        chartOptions: {
          chart: {
            type: 'pie',
            height: '100%', // S'assure que le graphique s'adapte à la hauteur de la div
            width: '100%',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true, 
            fontSize: '36px',
            color: "#ff0",
            name: {
              fontSize: '36px',
              color:  "#ff0",
              offsetY: 120
            },
            value: {
              offsetY: 76,
              fontSize: '26px',
              color: undefined,
              formatter: function (val) {
                return val + "%";
              }

            }
          },

          legend: {
            position: 'right',
            show: true,
          },
          plotOptions: {
            pie: {

              /* size: '45%',*/
              customScale: 1.1,
              offsetY: 180,

            }
          },
          grid2: {
            padding: {
              bottom: -80
            }
          },
          responsive2: [{
            breakpoint: 480,
            options: {
              chart: {
                height: 400
              },
              legend: {
                position: 'right',
                show: false,
              }
            }
          }]


        },
        series: [],
      };
    },
    watch: {
      'refresh': function () {
        console.log("PieChart WATCH refresh")
        if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      },
      'records': async function (val) {
        console.log("PieChart WATCH records")
        //   console.log(val)
        //let r = this.transformData(val);
        //  if (r == undefined) 
        //  r = { data: [12, 125, 855, 22], name: "" }

        this.series = val.records[0].values;


        this.chartOptions =
        {
          labels: val.records[0].categories
        }
        //     this.series[0].name = r.series[0].name;
      }
    },

    async mounted() {
      this.updateChartSize();


      window.addEventListener("resize", this.updateChartSize);

      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })


    },


    beforeUnmount() {
      window.removeEventListener("resize", this.updateChartSize);
    },


    methods: {
      onUpdate() {
        console.log("onUpdate")
        this.loaded = true;
      },
      onMounted() {
        console.log("onMounted")
        // this.loaded = true;
      },



      getDivSize() {

        const div = this.$refs["semi-donut-" + this.config.id]; // Référence à la div
        if (div) {
          const rect = div.getBoundingClientRect();
          this.divDimensions = {
            width: rect.width,
            height: rect.height,
            scale: this.chartOptions?.plotOptions?.pie?.customScale
          };
          console.log("Taille de la div:", this.divDimensions);
        }
        //  this.chartOptions.plotOptions.pie.customScale = 1.0
      },
      updateChartSize() {


        this.getDivSize();



        this.chartOptions.plotOptions = {
          pie: {

            /* size: '45%',*/
            /* customScale: (this.divDimensions.width / 10.0),*/
            /* offsetY: (this.divDimensions.height *2 ) /2,*/
            offsetY: 10
          }
        }

        //   this.chartOptions.chart = {
        //     ...this.chartOptions.chart,
        //     height: "100%",
        //     width: "100%",
        //   };

        //  if(this.chartOptions?.plotOptions?.pie?.customScale
      },

      transformData(data) {

        console.log("PIE - transformData transformData transformData transformData transformData", data.records)
        console.log(JSON.stringify(data))
        let series = []
        let labels = []


        {
          for (let i = 0; i < data.records.length; i++) {
            if (data.records[i]) {
              series.push(data.records[i]['y'])
              labels.push(data.records[i]['x'])

            }

          }
        }

        console.log("PIE - transformData transformData transformData transformData transformData", data.records)
        console.log(JSON.stringify(series))
        return { categories: labels, series: series }
      }
    },

  }
</script>