<template>
  <div class="mt-6 mb-6  flex flex-col  items-center justify-center w-full   ">
    <div class="w-12/12  w-full flex flex-row  relative ">
      <div class="w-6/12  w-full flex flex-col   ">

        <div class="  w-full    flex flex-row">
        
          <div class="   w-full  ">
            <div>

              <div class="flex flex-wrap w-full  ">
                <div v-for="(section,indexSection) in dashboard?.sections" :id="indexSection" :key="section?._id"
                  class="md:p-2 lg:p-2 xl:p-1 group  flex flex-wrap bg-gray-100  " :class="getSectionClass(section)">
              
                  <ChartSectionComponent class="w-auto h-auto" :id="section._id" :section="section" :tab="currentTab"
                    :refresh="onRefresh" :config="item" :class="section.class_chart" :reports="reports" freezer="false"
                    @editProperties="OnEditDiagram" @updateValue="OnUpdateValue" />
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
  import labels from "@/assets/lg/component_label.json"
  import ChartSectionComponent from "@/components/ChartSectionComponent.vue"
  import service from "@/services/FullService.vue"
  import { mapGetters } from "vuex";
  export default {
    name: 'ContentChartInForm',
    components: {
      ChartSectionComponent
    },
    props: {
      source: Object,
      value: Object,
      config: Object
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    data() {
      return {
        refresh: String, 
        reports: {},
        currentTab: 0,
        dashboard:

        {
          "name": {
            "FR": "Général",
            "EN": "Overview"
          },
          "sections": [
            {
              "_id": "f1014eef-6df2-42fa-88b1-9780f9b12c40",
              "name": {
                "fr": "Produits Finis",
                "en": "Produits Finis"
              },
              "category": {
                "fr": "Group 1",
                "en": "Group 1"
              },
              "section": {
                "fr": "Section 2",
                "en": "Section 2"
              },
              "class": "w-2/12",
              "image_id": "",
              "state": "ACTIVE",
              "position": "1",
              "description": {
                "fr": "Afficher le nombre total de produits finis.  Vous pouvez également filter suivant des paramétres comme la catégorie, status etc...",
                "en": "Afficher le nombre total de produits finis.  Vous pouvez également filter suivant des paramétres comme la catégorie, status etc..."
              },
              "items": [
                {
                  "class": "w-2/12",
                  "title": "Période 2023",
                  "unit": "Références",
                  "source": {
                    "records": [],
                    "value": "",
                    "id": "66448171aad52fc0df509cb5"
                  },
                  "graph": {
                    "type": "counter",
                    "value": ""
                  }
                }
              ],
              "class_title": " bg-white text-gray-900",
              "class_chart": " bg-white",
              "class_container": " bg-white",
              "tenant_id": "63f0aa0483c5da86a0024e9f",
              "class_counter": "bg-white",
              "class_unit": "bg-gray-400",
              "title": "Gamme Complète"
            },
            {
              "_id": "d55b87a4-cbcf-4f3b-85f2-903f225bac81",
              "name": {
                "fr": "Produits Aligot",
                "en": "Produits Aligot"
              },
              "category": {
                "fr": "Group 1",
                "en": "Group 1"
              },
              "section": {
                "fr": "Section 2",
                "en": "Section 2"
              },
              "class": "w-2/12",
              "image_id": "",
              "state": "ACTIVE",
              "position": "1",
              "description": {
                "fr": "Afficher le nombre total de produits finis.  Vous pouvez également filter suivant des paramétres comme la catégorie, status etc...",
                "en": "Afficher le nombre total de produits finis.  Vous pouvez également filter suivant des paramétres comme la catégorie, status etc..."
              },
              "items": [
                {
                  "class": "w-2/12",
                  "title": "Période 2023",
                  "unit": "Références",
                  "source": {
                    "records": [],
                    "value": "",
                    "id": "66d1db252be5f09c1e67920d"
                  },
                  "graph": {
                    "type": "counter",
                    "value": ""
                  }
                }
              ],
              "class_title": " bg-white text-gray-900",
              "class_chart": " bg-white",
              "class_container": " bg-white",
              "tenant_id": "63f0aa0483c5da86a0024e9f",
              "class_counter": "bg-white",
              "class_unit": "bg-gray-400",
              "title": "Gamme Fromage"
            }

          ]
        },
        output: {},

        labels: labels,
        lg: 'FR',

      }
    },
    watch: {
      'refresh': async function () {
        console.log("refresh --------------------")
        console.log("refresh --------------------")
        console.log("refresh --------------------")
        await this.loadDashboard();

      },
      value: function () {

        //   this.output = val;
      }
    },
    async mounted() {
      await this.loadDashboard();

    },
    methods: {
      onRefresh() {
        this.refresh = Math.random().toString(36).substring(7);
       // console.log("refresh *******************************")
      },
      async getRemoteData(id) {
        let filter = {}
        if (id == undefined) {
          return {
            total: 0,
            records: []
          }
        }
        let records = await service.generateReport("data-sources/" + id + '/charts', this.user?.token, Object.assign({}, { limit: 10, offset: 0 }, filter))
        return records
      },
      onRefeshChart: async function (item) {
        //  console.log("onRefeshChart onRefeshChart onRefeshChart onRefeshChart")
        //  console.log("id", item.id)
        //  console.log("source", item?.config?.source?.source?.id)
        //  console.log(item)

        let source_id = item?.config?.source?.id
        // let source_id = "663522bde3498f69e81035bd"

        let response = await this.getRemoteData(source_id, item)
        //  console.log("response <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", item.id)
        //  console.log(response)
        this.reports[item.id] = response
      },


      loadDashboard: async function () {
        if (this.user == undefined) return;
        let id = "679737b8f6bcf3b89d28e721";
        if (id == undefined) return
        let config = await service.getDataId("dashboards", id, this.user?.token, {})
        //  console.log(tpl)
        //  let config = { records: [tpl], total: 1 };
        if ((config != undefined)) {
          //  console.log(config)
          this.dashboard = config?.tabs[this.currentTab]
          this.tabs = config?.tabs
        //  this.listRight()
          return;
        }
        this.dashboard = { tabs: [] }
       // this.listRight()
      },
     
      getSectionClass: function (item) {
        if (item == undefined) return ''
        //   return 'grid grid-cols-' + item.cols.length + ' gap-4 mb-6'
        return ' ' + item.class
      },
      getData() {
        return "";
        //   return this.labels['BUTTON_EDIT'][this.lg];
      },


    }
  }
</script>