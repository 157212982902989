<template>
  <div class="w-full h-full  max-h-36  ">

    <div class="relative w-full h-full flex justify-center items-center px-2   "  :class="{'opacity-0' : !loaded, 'opacity-100': loaded }">
      <apexchart :id="`apex-${id}`" :ref="`graph-chart-${id}`" class="  chart h-full w-full h-min-16" type="area"
        @updated="onUpdate()" @mounted="onMounted()"
        :options="chartOptions" :series="series" height="100%" />
    </div>
  </div>
</template>


<style scoped>
  .chart {
    width: 100%;
    /* Prend toute la largeur du parent */
    height: 100%;
    /* Prend toute la hauteur du parent */
    max-width: 100%;
    /* Empêche le débordement horizontal */
    max-height: 100%;
    /* Empêche le débordement vertical */
    display: block;

    min-height: initial !important;
    /* Évite les comportements indésirables d'affichage */
  }

  .relative {
    position: relative;
    /* width: 100%; Occupe toute la largeur */
    /* height: ; Assure la hauteur complète height: 100%;*/

    /* display: flex;Pour centrer les éléments */
    /* justify-content: center; */
    /* align-items: center; */
    overflow: hidden;
    /* Empêche tout débordement */
    /* Empêche le débordement du contenu */
  }
</style>
<script>

  /*


  */
  import Handlebars from "handlebars";
  import VueApexCharts from "vue3-apexcharts";

  export default {
    name: 'LineChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      id: String,
      title: String,
      source: String,
      config: Object,
      records: Object,
      refresh: String,
    },
    data: function () {
      return {
        labelCounter: 0,
        loaded: false,
        serieTitle: "Température",
        chartOptions: {

          chart: {
            type: "area",
          //  height: 35,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
          //  curve: 'straight', 
            curve: 'smooth', 
            opacity: 0.1,
            width : 5
          },
          fill: {
            opacity: 0.1,
          },
          yaxis: {
            min: 0
          },
          //colors: ['#DCE6EC'],
          colors: ['#B51010'],
          title: {
            text: '',
            offsetX: 0,
            style: {
              fontSize: '24px',
            }
          },
          subtitle: {
            text: '',
            offsetX: 0,
            style: {
              fontSize: '14px',
            }
          }
        },
        series: [
          {
            data: [],
            name: "serie"
          }
        ]

      };
    },
    watch: {
      'refresh': function () {
        //  console.log("RowBarChart WATCH records")
        if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      },
      config: function () {
        // this.config?.source?.title = 
      },
      records: function (val) {
        console.log("Sparkline -------")
        console.log(JSON.stringify(this.records))

        if (val == undefined) return;
        if (val.records.length == 0) return;

        //  this.series = [{
        //      data: val
        //  }]
        this.labelCounter = 0
        let t = this.transformData(val);
        console.log(t)
        this.series[0] = { data: t }
        //     console.log(JSON.stringify(t))

     

        const template = Handlebars.compile(this.config.title);
          let title = (template(val));


        this.chartOptions = {

          title:
          {
            text: title
          },
          subtitle:
          {
            text: this.config.subtitle
          }
        }


      }



    },
    async mounted() {

      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })

    },
    methods:
    {

      onUpdate() {
        //  console.log("onUpdate")
        this.loaded = true;
      },
      onMounted() {
        //   console.log("onMounted")
        this.loaded = false;
      },
      transformData(records) {

        let data = []
        for (let i = 0; i < records.records.length; i++) {


          data.push({ x: records.records[i].x, y: records.records[i].y })

        }

        return data
      },

    }
  }
</script>