<template>


    <div :ref="`heat-map-${config.id}`" class="relative  w-full   flex justify-center items-center px-2  min-h-36">
        <apexchart class=" w-full   pt-0" type="heatmap" :options="chartOptions" :series="series" height="100%"
            width="100%" @updated="onUpdate()" @mounted="onMounted()"
            :class="{'opacity-100' : !loaded, 'opacity-100': loaded }" />
    </div>

</template>


<style scoped>
    .chart {
        width: 100%;
        /* Prend toute la largeur du parent */
        height: 100%;
        /* Prend toute la hauteur du parent */
        max-width: 100%;
        /* Empêche le débordement horizontal */
        max-height: 100%;
        /* Empêche le débordement vertical */
        display: block;

        min-height: initial !important;
        /* Évite les comportements indésirables d'affichage */
    }

    .relative {
        position: relative;
        /* width: 100%; Occupe toute la largeur */
        /* height: ; Assure la hauteur complète */
        height: 100%;
        /* display: flex;Pour centrer les éléments */
        /* justify-content: center; */
        /* align-items: center; */
        overflow: hidden;
        /* Empêche tout débordement */
        /* Empêche le débordement du contenu */
    }
</style>
<script>
    import VueApexCharts from "vue3-apexcharts";
    export default {
        name: 'SemiDonutChart',
        components: {
            apexchart: VueApexCharts,
        },
        props: {
            parent: String,
            title: String,
            records: Object,
            config: Object
        },
        data: function () {
            const self = this  


            return {
                loaded: false,
                ratio: 1.0,
                divDimensions: {
                    width: 0,
                    height: 0, scale: 1.0
                },
                chartOptions: {
                    chart: {
                        type: 'heatmap',
                        //height: '100%', // S'assure que le graphique s'adapte à la hauteur de la div

                        height: '100%',
                        width: '100%',
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false,
                        }
                    },
                    plotOptions: {
                        heatmap: {

                            colorScale: {
                                shadeIntensity: 0.5, ranges: [
                                    { from: 0, to: 0, color: "#D3D3D3", name: "No Activity" } // Gris pour 0
                                ], colorScale: {
                                    inverse: false,
                                    min: 0,
                                    max: 500, // Ajustez en fonction de vos données
                                    gradient: {
                                        shade: "light", // Choisir le style du dégradé
                                        type: "vertical", // Dégradé vertical (ou horizontal)
                                        stops: [0, 25, 50, 75, 100], // Points de transition (en %)
                                        colorFrom: "#D3EEDA", // Vert clair (valeurs basses)
                                        colorTo: "#006400"   // Vert foncé (valeurs élevées)
                                    }
                                }
                            },
                            colorScale2: {
                                ranges: [
                                    { from: 0, to: 0, color: "#D3D3D3", name: "No Activity" }, // Gris pour les zéros


                                    { from: 1, to: 50, color: '#00A100', name: 'Low' },
                                    { from: 51, to: 100, color: '#128FD9', name: 'Medium' },
                                    { from: 101, to: 150, color: '#FFB200', name: 'High' },
                                    { from: 151, to: 200, color: '#FF0000', name: 'Extreme' }
                                ]
                            }
                        }
                    },
                    xaxis: {
                        type: 'category',
                        categories: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
                    },
                    grid: {
                        padding: {
                            right: 0
                        }
                    },
                    dataLabels: {
                        enabled: true, // Active l'affichage des données
                        style: {
                            colors: ["#000000"] // Couleur des données affichées
                        },
                        formatter: function (value, opts) {
                            console.log(opts)
                            // Formatter pour afficher le jour du mois
                            const seriesIndex = opts.seriesIndex; // Index de la semaine
                            const dataPointIndex = opts.dataPointIndex; // Index du jour de la semaine
                            const baseDate = new Date(self.records.records[seriesIndex].dates[dataPointIndex]); // Exemple : début décembre 2024
                          
                            return baseDate.getDate()  ; // Retourne le numéro du jour dans le mois
                        }
                    },
                    colors: ["#008FFB"],
                    title: {
                        text: 'HeatMap Chart (Single color)'
                    },

                },
                series: [

                ],
            };
        },
        watch: {

            'records': async function (val) {
                console.log("-------------------------------------------------")
                 console.log(JSON.stringify(val))
          //      let r = this.transformData(val);
            //    if (r == undefined) r = { data: [], name: "" }

             /*   let rawData = val.records
              

                rawData.sort((a, b) => {
                    const weekA = parseInt(a.name, 10);
                    const weekB = parseInt(b.name, 10);
                    return weekA - weekB; // Trier par numéro de semaine
                });

                // Trier les jours pour chaque semaine
                rawData.forEach((series) => {
                    series.data.sort((a, b) => {
                        const daysOrder = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                        return daysOrder.indexOf(a.x) - daysOrder.indexOf(b.x); // Trier par ordre des jours
                    });
                });
*/
let records = []
                for (let i =0 ;i < val.records.length; i++) {
                   
                    console.log(val.records[i].name)

                    records.push({name : val.records[i].name, data : val.records[i].data})
                }

                this.series = val.records;

                

                // this.chartOptions =
                // {
                //   labels: r.categories
                // }
                //     this.series[0].name = r.series[0].name;
            }
        },

        async mounted() {
            // this.updateChartSize();


            // window.addEventListener("resize", this.updateChartSize);

            if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })


        },


        beforeUnmount() {
            window.removeEventListener("resize", this.updateChartSize);
        },


        methods: {
            onUpdate() {
                console.log("onUpdate")
                this.loaded = true;
            },
            onMounted() {
                console.log("onMounted")
                // this.loaded = true;
            },



            getDivSize() {

                const div = this.$refs["semi-donut-" + this.config.id]; // Référence à la div
                if (div) {
                    const rect = div.getBoundingClientRect();
                    this.divDimensions = {
                        width: rect.width,
                        height: rect.height,
                        scale: this.chartOptions?.plotOptions?.pie?.customScale
                    };
                    console.log("Taille de la div:", this.divDimensions);
                }
                //  this.chartOptions.plotOptions.pie.customScale = 1.0
            },
            updateChartSize() {


                this.getDivSize();



                this.chartOptions.plotOptions = {
                    pie: {

                        /* size: '45%',*/
                        /* customScale: (this.divDimensions.width / 10.0),*/
                        /* offsetY: (this.divDimensions.height *2 ) /2,*/
                        offsetY: 10,
                        donut: {
                            size: '45%',
                        }
                    }
                }

                //   this.chartOptions.chart = {
                //     ...this.chartOptions.chart,
                //     height: "100%",
                //     width: "100%",
                //   };

                //  if(this.chartOptions?.plotOptions?.pie?.customScale
            },

            transformData(data) {

                //       console.log("DONUT - transformData transformData transformData transformData transformData", data.records)
                //       console.log(JSON.stringify(data))
                let series = []
                let labels = {}


                {
                    for (let i = 0; i < data.records.length; i++) {
                        if (data.records[i]) {
                            series.push(data.records[i]['value'])
                            labels[data.records[i]['label']] = data.records[i]['value']
                        }

                    }
                }

                return { categories: Object.keys(labels), series: [{ name: "test", data: series }] }
            }
        },

    }
</script>