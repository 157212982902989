<template>
  <div class="relative  w-full h-64 flex justify-center items-center px-2  ">
    <apexchart :id="`apex-range-area-${id}`" :ref="`apex-range-area-${id}`" class="chart h-full w-full h-min-24"
      type="rangeArea" @updated="onUpdate()" @mounted="onMounted()"
      :class="{'opacity-100' : !loaded, 'opacity-100': loaded }" :options="chartOptions" :series="series"
      height="100%" />
  </div>
</template>




<style scoped>
  .chart {
    width: 100%;
    /* Prend toute la largeur du parent */
    height: 100%;
    /* Prend toute la hauteur du parent */
    max-width: 100%;
    /* Empêche le débordement horizontal */
    max-height: 100%;
    /* Empêche le débordement vertical */
    display: block;

    min-height: initial !important;
    /* Évite les comportements indésirables d'affichage */
  }

  .relative {
    position: relative;
    /* width: 100%; Occupe toute la largeur */
    /* height: ; Assure la hauteur complète height: 100%;*/

    /* display: flex;Pour centrer les éléments */
    /* justify-content: center; */
    /* align-items: center; */
    overflow: hidden;
    /* Empêche tout débordement */
    /* Empêche le débordement du contenu */
  }
</style>
<script>

  /*


  */

  import VueApexCharts from "vue3-apexcharts";

  export default {
    name: 'LineChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      id: String,
      title: String,
      source: String,
      config: Object,
      records: Object,
      refresh: String,
    },
    data: function () {
      return {
        labelCounter: 0,
        series: [ {name: "-", data: []} ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            type: 'rangeArea'
          },
          stroke: {
            curve: 'monotoneCubic'
          },
          title: {
            text: 'New York Temperature (all year round)'
          },
          markers: {
            hover: {
              sizeOffset: 5
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            labels: {
              rotate: -45,
              formatter: (value) => {
                //  const result = this.labelCounter % 5 === 0 ? value : '';
                //  this.labelCounter++; // Incrémenter le compteur
                return value;
              },
            },
          },
          yaxis: {
            labels: {
              formatter: (val) => {
                return val + '°C'
              }
            }
          }
        },

        loaded: false,
        serieTitle: "Température",
      };
    },
    watch: {
      'refresh': function () {
        console.log("RowBarChart WATCH refresh")
        if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      },
       config: function () {
      // this.config?.source?.title = 
       },
      'records': function (val) {
        console.log("RowBarChart WATCH records")
        if (val == undefined) return;
        if (val.length == 0) return;

        this.labelCounter = 0
        let t = this.transformData(val);

        this.series[0] = { data: t }
      }
    },
    async mounted() {
      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
    },
    methods:
    {
      onUpdate() {
        //  console.log("onUpdate")
        this.loaded = true;
      },
      onMounted() {
        //   console.log("onMounted")
        this.loaded = false;
      },
      transformData(records) {
        console.log("records ", records)
        let data = []
        for (let i = 0; i < records.records.length; i++) {

          let y = records.records[i].y

          if (!Array.isArray(y)) y = [0, 0]
          else y = [y[0], y[1]]

          data.push({ x: records.records[i].x, y: y })
        }
        console.log("transformData ", data)
        return data
      },
    }
  }
</script>