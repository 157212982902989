<template>

  <div class="w-full   ">

    <component :is="mapComponent(charttype)" :id="id" :period="period" :records="localReports" :config="config"
      :refresh="refresh" @refresh-chart="onRefreshChart" />


  </div>
</template>

<style>

   
  @media (max-width: 639px) {
    .w-full-b4f {
    width: 100%;
  }
  }


</style>

<script>

  import Handlebars from "handlebars";



  //  import common_labels from "@/assets/lg/common.json"
  import service from "@/services/FullService.vue"
  import CounterChart from "@/components/charts/CounterChart.vue"
  import ColumnBarChart from "@/components/charts/ColumnBarChart.vue"
  import LineChart from "@/components/charts/LineChart.vue"
  import SemiDonutChart from "@/components/charts/SemiDonutChart.vue"
  import TreeMapChart from "@/components/charts/TreeMapChart.vue"
  import HeatMapChart from "@/components/charts/HeatMapChart.vue"
  import RowBarChart from "@/components/charts/RowBarChart.vue"
  import DonutChart from "@/components/charts/DonutChart.vue"
  import AreaChart from "@/components/charts/AreaChart.vue"
  import RangeAreaChart from "@/components/charts/RangeAreaChart.vue"
  import PieChart from "@/components/charts/PieChart.vue"
  import RadarChart from "@/components/charts/RadarChart.vue"
  import RadialBarChart from "@/components/charts/RadialBarChart.vue"
  import FigureSparkLineChart from "@/components/charts/FigureSparkLineChart.vue"
  import FigureSparkLineListChart from "@/components/charts/FigureSparkLineListChart.vue"
  import TableChart from "@/components/charts/TableChart.vue"

  /*
 
  import HeaderGraph from "@/components/charts/HeaderGraph.vue"

 
  import ComboLineBarChart from "@/components/charts/ComboLineBarChart.vue"
  import RowBoxPlotChart from "@/components/charts/RowBoxPlotChart.vue"
  import ColumnBoxPlotChart from "@/components/charts/ColumnBoxPlotChart.vue"

  import RadialBarChart from "@/components/charts/RadialBarChart.vue"
  import ScatterChart from "@/components/charts/ScatterChart.vue"
  import PolarChart from "@/components/charts/PolarChart.vue"
 
  import Fake from "@/components/charts/Fake.vue"
  import TimeLineChart from "@/components/charts/TimeLineChart.vue"

  import FunnelChart from "@/components/charts/FunnelChart.vue"
   

  import CandleStickChart from "@/components/charts/CandleStickChart.vue"
 
  import BubbleChart from "@/components/charts/BubbleChart.vue"
  
*/

  //import { Modal } from 'flowbite';
  import { mapGetters } from "vuex";
  export default {
    name: 'ChartComponent',
    components: {
      CounterChart, //ok
      ColumnBarChart, //ok
      LineChart, //ok
      AreaChart, //ok
      DonutChart, //ok
      SemiDonutChart,  //ok
      RowBarChart, //ok
      TreeMapChart,   //ok
      HeatMapChart,   //ok
      RangeAreaChart,//ok
      PieChart,//ok
      RadarChart,
      RadialBarChart,//ok
      FigureSparkLineChart,//ok
      FigureSparkLineListChart,//ok
      TableChart,//ok
      /*     
      
           Fake,
          
         
           RangeAreaChart,
           TreeMapChart,
        
           FunnelChart,
         
         
           PolarChart,
         
          
           TimeLineChart,
           ComboLineBarChart,
           CandleStickChart,
           RowBoxPlotChart,
           ColumnBoxPlotChart,
         
        
           BubbleChart,
           ScatterChart,
          
           FigureSparkLineChart,
           HeaderGraph*/
    },

    props: {
      id: String,
      charttype: String,
      config: Object,
      period: Object,
      refresh: String
    },
    data() {
      return {
        localReports: {},
        source: null // A Garder ???
      }
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    mounted() { },
    methods: {

      mapComponent: function (type) {
        console.log("mapComponent mapComponent", type)
        if (type == 'table') return TableChart;
        if (type == 'counter') return CounterChart;
        if (type == 'column-bar') return ColumnBarChart;
        if (type == 'semi-donut') return SemiDonutChart;
        if (type == 'donut') return DonutChart;
        if (type == 'tree-map') return TreeMapChart;
        if (type == 'heat-map') return HeatMapChart;
        if (type == 'row-bar') return RowBarChart;
        if (type == 'line') return LineChart;
        if (type == 'area') return RangeAreaChart;
        if (type == 'pie') return PieChart;
        if (type == 'radar') return RadarChart;
        if (type == 'radial-bar') return RadialBarChart;
        if (type == 'figure-spark-line') return FigureSparkLineChart;
        if (type == 'figure-spark-line-list') return FigureSparkLineListChart;
        return LineChart;
      },

      getSectionHeight: function (item) {
        if (item == undefined) return "h-96"
        return 'h-' + item.height
      },

      getContainerClass: function (item) {
        if (item == undefined) return ''
        //   return 'grid grid-cols-' + item.cols.length + ' gap-4 mb-6'
        return ' ' + item.class_container
      },

      getSectionClass: function (item) {
        if (item == undefined) return ''
        //   return 'grid grid-cols-' + item.cols.length + ' gap-4 mb-6'
        return ' ' + item.class
      },

      getBodyClass: function (col) {
        // if (item == undefined) return
        if (col == undefined) return ''
        return col.class
        //return 'h-' + col.height + ' grid   gap-4  grid grid-cols-' + col.items.length + ' gap-4 mb-6'
      },

      async getRemoteData(id) {

        let context =
        {
          "_id": this.$route.params.rid,
        }
        let filter = {}

        if (this.config.source.filter) {

          let keys = Object.keys(this.config.source.filter)
          for (let i = 0; i < keys.length; i++) {
            const template = Handlebars.compile(this.config.source.filter[keys[i]]);


            filter[keys[i]] =  (template(context));

            
            }


        }

        if (id == undefined) {
          return {
            total: 0,
            sum: 0,
            records: []
          }
        }
        let config = this.config
        //  config.source.addon = [ { "$match" : {"supplier_id": {"$oid": "66c763dee163901c0e523619"}}]

        let records = {}
        if (this.config.version == 2) {
          records = await service.generateReport2("data-sources/" + id + '/charts2', this.user?.token, Object.assign({}, { limit: 10, offset: 0 }, filter), config)

        }
        else {
          records = await service.generateReport("data-sources/" + id + '/charts', this.user?.token, Object.assign({}, { limit: 10, offset: 0 }, filter))
        }

        //console.log("Chart mounted")
        //console.log(JSON.stringify(records))
        return records
      },
      onRefreshChart: async function (item) {

        // alert(JSON.stringify(this.config))
        console.log("onRefeshChart onRefeshChart onRefeshChart onRefeshChart")
        console.log("id", item.id)
        console.log("source", item?.config?.source?.id)
        console.log("item------------------------------------")
        console.log(JSON.stringify(item))

        let source_id = item?.config?.source?.id
        // let source_id = "663522bde3498f69e81035bd"

        let response = await this.getRemoteData(source_id)

        console.log("response <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", item.id)
        //  console.log(response)
        //this.reports[item.id] = response
        this.localReports = response
      },



    },
  }

</script>