 

<template>
    <div class="relative  w-full h-64 flex justify-center items-center px-2  ">
        <apexchart :id="`apex-${id}`" :ref="`graph-chart-${id}`" class="chart h-full w-full h-min-24" type="area"
            @updated="onUpdate()" @mounted="onMounted()" :class="{'opacity-100' : !loaded, 'opacity-100': loaded }"
            :options="chartOptions" :series="series" height="100%" />
    </div>
</template>




<style scoped>
    .chart {
        width: 100%;
        /* Prend toute la largeur du parent */
        height: 100%;
        /* Prend toute la hauteur du parent */
        max-width: 100%;
        /* Empêche le débordement horizontal */
        max-height: 100%;
        /* Empêche le débordement vertical */
        display: block;

        min-height: initial !important;
        /* Évite les comportements indésirables d'affichage */
    }

    .relative {
        position: relative;
        /* width: 100%; Occupe toute la largeur */
        /* height: ; Assure la hauteur complète height: 100%;*/

        /* display: flex;Pour centrer les éléments */
        /* justify-content: center; */
        /* align-items: center; */
        overflow: hidden;
        /* Empêche tout débordement */
        /* Empêche le débordement du contenu */
    }
</style>
<script>

    /*
 

    */

    import VueApexCharts from "vue3-apexcharts";

    export default {
        name: 'LineChart',
        components: {
            apexchart: VueApexCharts,
        },
        props: {
            id: String,
            title: String,
            source: String,
            config: Object,
            records: Object,
            refresh: String,
        },
        data: function () {
            return {
                labelCounter: 0,
                loaded: false,
                serieTitle: "Température",
                chartOptions: {

                    chart: {

                        type: "line",
                        stacked: false,
                        zoom: {
                            enabled: false
                        },


                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                                reset: false,
                                customIcons: []
                            },
                            export: {

                            },
                            autoSelected: 'zoom'
                        },

                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ["#FF1654", "#247BA0"],

                    stroke: {
                        width: [2, 2],curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: "20%"
                        }
                    },
                    xaxis: {
                        //type: 'datetime',
                        //categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
                        labels: {
                            rotate: -45,
                            formatter: (value) => {
                                const result = this.labelCounter % 5 === 0 ? value : '';
                                this.labelCounter++; // Incrémenter le compteur
                                return result;
                            },
                        },
                    },
                    yaxis: [],
                    tooltip: {
                        shared: false,
                        intersect: true,
                        x: {
                            show: false
                        }
                    },
                    legend: {
                        horizontalAlign: "left",
                        offsetX: 40
                    }
                },
                series: [
                    {
                        data: [],
                        name: "qqq"
                    }
                ]

            };
        },
        watch: {
            'refresh': function () {
                //  console.log("RowBarChart WATCH records")
                if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
            },
            config: function () {
                // this.config?.source?.title = 
            },
            records: function (val) {
                //   console.log("LINECHART -------")
                //   console.log(JSON.stringify(this.records))

                if (val == undefined) return;
                if (val.length == 0) return;

                //  this.series = [{
                //      data: val
                //  }]
                this.labelCounter = 0
                let t = this.transformData(val);
                console.log(t)
                this.series[0] = { data: t }
                //     console.log(JSON.stringify(t))

                /*    let k = Object.keys(t)
    
                    for (let i = 0; i < k.length; i++) {
                        //if(i==0) 
                        this.series[i] = { name: k[i], data: t[k[i]] }
                    }
    
    
                    if (this.config?.graph?.yaxis) {
                        this.chartOptions = {
                            yaxis: this.config.graph.yaxis
                        }
                    }*/



                //let el = this.$refs['graph-chart-' + this.id]
                //el.refresh()
                // if (this.$refs['graph-chart-' + this.id])
                //     this.$refs['graph-chart-' + this.id].refresh();



            }
            /*    records: {
                
                    data(values) {
                  
                        this.series = values;
                    },
                    deep: true
                }*/





        },
        async mounted() {

            if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })

        },
        methods:
        {
            /*
"total":4,"records":[{"x":"2024-05-17T08:39:28.494Z","y":300},{"x":"2024-05-17T09:15:42.439Z","y":620},{"x":"2024-05-17T09:51:36.006Z","y":125},{"x":"2024-05-17T09:54:31.228Z","y":12}]}
            */
            onUpdate() {
                //  console.log("onUpdate")
                this.loaded = true;
            },
            onMounted() {
                //   console.log("onMounted")
                this.loaded = false;
            },
            transformData(records) {

                let data = []
                for (let i = 0; i < records.records.length; i++) {


                    data.push({ x: records.records[i].x, y: records.records[i].y })

                }

                return data
            },
            transformDataOld(records) {

                let data = {}

                for (let i = 0; i < records.records.length; i++) {

                    if (data[records.records[i].color] == undefined) {
                        data[records.records[i].color] = []
                    }
                    if (records?.records[i]?.y)
                        if (typeof records.records[i].y == "string") records.records[i].y = records.records[i].y.replaceAll(" ", "")
                    if (records.records[i].y == "Ininterpretable") records.records[i].y = "0"

                    let y = parseFloat(records.records[i].y)
                    let date_x = records.records[i].x.year + "-0" + (records.records[i].x.month + 1) + "-" + records.records[i].x.date

                    data[records.records[i].color].push({
                        //  x: new Date(records.records[i].x),
                        x: new Date(date_x).getTime(),
                        y: y ? y.toFixed(1) : 100
                    })
                }

                data[records.records[0].color].push({
                    //  x: new Date(records.records[i].x),
                    x: new Date("2024-08-21").getTime(),
                    y: 0
                })




                //     console.log(JSON.stringify(data))
                return data
            }
        }
    }
</script>