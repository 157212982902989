<template>
    <div class="relative  w-full     flex justify-center items-center px-2   min-h-36">

        <div class="overflow-y-auto relative pb-2 w-full ">
            <table class="table-fixed w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase " :class="config?.class_header">
                    <tr class=" h-10 ">
                        <th v-for=" col in config.cols" :key="col.id" scope="col" class="px-0 py-0  h-10  "
                            :class="col?.class_header">
                            <div class="pr-2   h-full   flex items-center" :class="col?.class_header_title">
                                {{ col.name ? col.name[lg] : "-" }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="records2 != undefined " style="min-height:200px">
                    <tr v-for="record in records2" :key="record._id" class="  h-10 border-b dark:border-gray-600   ">
                        <td v-for="col in config?.cols" :key="col.id" class="px-0 py-0  "
                            :class="col?.class_cell">
                            <div class="pr-2   h-full     items-center" :class="col?.class_data">
                                {{getData(col?.data,record)}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>


<style scoped>
    .chart {
        width: 100%;
        /* Prend toute la largeur du parent */
        height: 100%;
        /* Prend toute la hauteur du parent */
        max-width: 100%;
        /* Empêche le débordement horizontal */
        max-height: 100%;
        /* Empêche le débordement vertical */
        display: block;

        min-height: initial !important;
        /* Évite les comportements indésirables d'affichage */
    }

    .relative {
        position: relative;
        /* width: 100%; Occupe toute la largeur */
        /* height: ; Assure la hauteur complète */
        height: 100%;
        /* display: flex;Pour centrer les éléments */
        /* justify-content: center; */
        /* align-items: center; */
        overflow: hidden;
        /* Empêche tout débordement */
        /* Empêche le débordement du contenu */
    }
</style>


<script>
    import Handlebars from "handlebars";
    export default {
        name: 'TableChart',
        components: {

        },
        props: {

            title: String,
            records: Object,
            config: Object,
            refresh: String
        },
        data: function () {
            return {
                lg: "FR",
                records2: [],
                loaded: false,
                series: [
                    {
                        data: []
                    }
                ]
            }
        },
        watch: {
            'refresh': function () {
                //  console.log("RowBarChart WATCH records")
                if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
            },


            'records': async function (val) {

                this.records2 = val.records;


            }


        },
        async mounted() {
            Handlebars.registerHelper("format2Digits", function (options) {
                //return options.fn(this);

                if (options.fn(this) == undefined) return "0.00"
                if (options.fn(this) == "") return "0.00"
                if (options.fn(this) == "-") return "0.00"
                const formattedNumber = new Intl.NumberFormat('fr-FR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(options.fn(this));
                return new Handlebars.SafeString(formattedNumber);
            });
            if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.id })


        },

        methods: {
            getData(input, record) {

                const template = Handlebars.compile(input);

                return (template(record));
            },
            onUpdate() {

                this.loaded = true;
            },
            onMounted() {

            },

            mapLabels: function (name) {

                return name;
            },

        }
    }
</script>