<template>
  <div class="p-3 border-2 border-gray-200   overflow-hidden rounded-lg w-full flex flex-col" :class="getContainerClass(section)">

    <h2 v-if="section?.title"
      class="w-full   text-black font-bold text-lg pb-1 pt-1 text-left flex flex-row items-center "
      :class="section.class_title">
      <div class="w-full px-2 truncate h-8 pr-6 ">
        {{ section?.title }}
      </div>
      <div
        class="hidden w-6 h-6   text-gray-600 opacity-0 justify-center items-center text-sm cursor-pointer group-hover:opacity-100"
        @click="OnEditDiagram(section)">
         <i class="fa-solid fa-gear" />
      </div>
      <div  @mouseover2="OnSelectDiagramPeriod(section)"
        class="hidden w-12 h-6  text-gray-400  justify-center items-center text-sm cursor-pointer  ">
        <button :id="`select-dashboard-period-button-${section?._id}`" data2-dropdown-toggle="dropdownDelay"
          data2-dropdown-delay="500" data2-dropdown-trigger="hover"
          class="   text-gray-400   font-normal rounded-lg text-xs  text-right justify--end inline-flex items-center "
          type="button">
          <div class="w-6 text-right">{{displayPeriod}}</div>
          <svg class="w-2.5 h-2.5 ms-1 opacity-0 group-hover:opacity-100" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 4 4 4-4" />
          </svg>
        </button>
        <!-- Dropdown menu -->
        <div :id="`select-dashboard-period-menu-${section?._id}`"
          class="z-10 hidden bg-gray-500 divide-y divide-gray-100 rounded-sm   shadow w-20  dark:bg-gray-700">
          <ul class="py-2 text-xs text-white dark:text-gray-200" aria-labelledby="dropdownDelayButton">
            <li>
              <div class="block px-2 py-1 hover:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-white"
                @click="selectPeriod(section,'7days')">7 Jours</div>
            </li>
            <li>
              <div class="block px-2 py-1 hover:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-white"
                @click="selectPeriod(section,'15days')">15 Jours</div>
            </li>
            <li>
              <div class="block px-2 py-1 hover:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-white"
                @click="selectPeriod(section,'30days')">30 Jours</div>
            </li>
            <li>
              <div class="block px-2 py-1 hover:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-white"
                @click="selectPeriod(section,'90days')">90 Jours</div>
            </li>
            <li>
              <div class="block px-2 py-1 hover:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-white"
                @click="selectPeriod(section,'180days')">180 Jours</div>
            </li>
            <li>
              <div class="block px-2 py-1 hover:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-white"
                @click="selectPeriod(section,'365days')">365 Jours</div>
            </li>
          </ul>
        </div>

      </div>
    </h2>
    <div class="w-full   h-full" :class="getBodyClass(section)">
      <div v-for="item in section?.items" :key="item?.id" class=" w-full h-full items-center justify-center"
        :class="getContainerClass(item)">
 
        <ChartComponent class="w-auto h-full" :class="section.class_chart" :id="item.id"
          :charttype="item?.graph?.type" :refresh="refresh" :config="item" :period="section?.period" freezer="false" />
      </div>
    </div>
  </div>
</template>

<script>
  /*



  */

  import ChartComponent from "@/components/ChartComponent.vue"
  import { Dropdown } from 'flowbite';
  import { mapGetters } from "vuex";
  export default {
    name: 'ChartSectionComponent',
    components: {
      ChartComponent
    },

    props: {
      id: String,
      config: Object,
      section: Object,
      reports: Object,
      refresh: String,
      source: String,
      tab: Number,
      template: Object,
      type: String,
    },
    data() {
      return {

        dropdowns: [],
      }
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
      displayPeriod: function () {
        switch (this.section?.period?.type) {
          case '7days': return '7j';
          case '15days': return '15j';
          case '30days': return '30j';
          case '90days': return '90j';
          case '180days': return '180j';
          case '365days': return '365j';
        }
        return '?'
      }
    },

    mounted() {



    },
    methods: {
      OnEditDiagram: function (/*item*/) {
      //  this.$emit("editProperties", item)
      },

      selectPeriod: function (item, n) {
        console.log(n)
        item.period.type = n
        this.$emit("updateValue", { tab: this.tab , section: item} )
      
        this.dropdowns[item._id].hide()
       

        //  let body = {



        //  }
       
      },
      OnSelectDiagramPeriod: function (item) {
        if (this.dropdowns[item._id] == undefined) {
          const options = {
            placement: 'bottom',
            triggerType: 'none',
            offsetSkidding: 0,
            offsetDistance: 10,
            delay: 300,
            ignoreClickOutsideClass: false,
            onHide: () => {
              console.log('dropdown has been hidden');
            },
            onShow: () => {
              console.log('dropdown has been shown');
            },
            onToggle: () => {
              console.log('dropdown has been toggled');
            },
          };
          let idmenu = "select-dashboard-period-menu-" + item?._id;
          let idbutton = "select-dashboard-period-button-" + item?._id;

          // instance options object
          const instanceOptions = {
            id: idmenu,
            override: true
          };
          const $targetEl = document.getElementById(idmenu);
          const $triggerEl = document.getElementById(idbutton);
          if ($triggerEl != undefined) {
            this.dropdowns[item._id] = new Dropdown($targetEl, $triggerEl, options, instanceOptions);
          }
        }
        this.dropdowns[item._id].show()
        //   this.recordEditDiagram = item
        //   this.editDiagramModal.show();
      },
      getSectionHeight: function (item) {

        if (item == undefined) return "h-96"
        return 'h-' + item.height
      },
      getContainerClass: function (item) {
        if (item == undefined) return ''
        //   return 'grid grid-cols-' + item.cols.length + ' gap-4 mb-6'
        return ' ' + item.class_container
      },
      getSectionClass: function (item) {
        if (item == undefined) return ''
        //   return 'grid grid-cols-' + item.cols.length + ' gap-4 mb-6'
        return ' ' + item.class
      },
      getBodyClass: function (col) {
        // if (item == undefined) return
        if (col == undefined) return ''
        return col.class
        //return 'h-' + col.height + ' grid   gap-4  grid grid-cols-' + col.items.length + ' gap-4 mb-6'
      }

    },
  }

</script>