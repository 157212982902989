<template>
  <div class=" py-2 relative flex flex-col    min-w-0 break-words    shadow-none   h-full   "
    :class="fixZoneClass(config.class)">

    <form class="w-full flex flex-col  " >
      <div v-if="isDeveloper == true" class="absolute top-0 right-1 bg-red-300  flex cursor-pointer text-gray-500  ">
        <button :id="`section-${config.id}-dropdown-button`" :data-dropdown-toggle="`section-${config.id}-dropdown`"
          class="inline-flex items-center text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-700 p-1.5 dark:hover-bg-gray-800 text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
          type="button">
          <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
          </svg>
        </button>

        <div :id="`section-${config.id}-dropdown`"
          class="hidden z-40 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
          <ul class="py-1 text-sm" :aria-labelledby="`section-${config.id}-dropdown-button`">
            <li>
              <button type="button"
                class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-700 dark:text-gray-200"
                @click="manageSection(config)">
                <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                </svg>
                Modifier
              </button>
            </li>
            <li>
              <button type="button"
                class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-700 dark:text-gray-200"
                @click="addSection(config)">
                <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                Ajouter une section
              </button>
            </li>
            <li>
              <button type="button"
                class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-700 dark:text-gray-200"
                @click="addSection(config)">
                <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                Ajouter une zone
              </button>
            </li>
            <li>
              <button type="button"
                class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 text-red-500 dark:hover:text-red-400"
                @click="deleteSection(config)">
                <svg class="w-4 h-4 mr-2" viewbox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true">
                  <path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor"
                    d="M6.09922 0.300781C5.93212 0.30087 5.76835 0.347476 5.62625 0.435378C5.48414 0.523281 5.36931 0.649009 5.29462 0.798481L4.64302 2.10078H1.59922C1.36052 2.10078 1.13161 2.1956 0.962823 2.36439C0.79404 2.53317 0.699219 2.76209 0.699219 3.00078C0.699219 3.23948 0.79404 3.46839 0.962823 3.63718C1.13161 3.80596 1.36052 3.90078 1.59922 3.90078V12.9008C1.59922 13.3782 1.78886 13.836 2.12643 14.1736C2.46399 14.5111 2.92183 14.7008 3.39922 14.7008H10.5992C11.0766 14.7008 11.5344 14.5111 11.872 14.1736C12.2096 13.836 12.3992 13.3782 12.3992 12.9008V3.90078C12.6379 3.90078 12.8668 3.80596 13.0356 3.63718C13.2044 3.46839 13.2992 3.23948 13.2992 3.00078C13.2992 2.76209 13.2044 2.53317 13.0356 2.36439C12.8668 2.1956 12.6379 2.10078 12.3992 2.10078H9.35542L8.70382 0.798481C8.62913 0.649009 8.5143 0.523281 8.37219 0.435378C8.23009 0.347476 8.06631 0.30087 7.89922 0.300781H6.09922ZM4.29922 5.70078C4.29922 5.46209 4.39404 5.23317 4.56282 5.06439C4.73161 4.8956 4.96052 4.80078 5.19922 4.80078C5.43791 4.80078 5.66683 4.8956 5.83561 5.06439C6.0044 5.23317 6.09922 5.46209 6.09922 5.70078V11.1008C6.09922 11.3395 6.0044 11.5684 5.83561 11.7372C5.66683 11.906 5.43791 12.0008 5.19922 12.0008C4.96052 12.0008 4.73161 11.906 4.56282 11.7372C4.39404 11.5684 4.29922 11.3395 4.29922 11.1008V5.70078ZM8.79922 4.80078C8.56052 4.80078 8.33161 4.8956 8.16282 5.06439C7.99404 5.23317 7.89922 5.46209 7.89922 5.70078V11.1008C7.89922 11.3395 7.99404 11.5684 8.16282 11.7372C8.33161 11.906 8.56052 12.0008 8.79922 12.0008C9.03791 12.0008 9.26683 11.906 9.43561 11.7372C9.6044 11.5684 9.69922 11.3395 9.69922 11.1008V5.70078C9.69922 5.46209 9.6044 5.23317 9.43561 5.06439C9.26683 4.8956 9.03791 4.80078 8.79922 4.80078Z" />
                </svg>
                Supprimer
              </button>
            </li>
          </ul>
        </div>
      </div>
      <h6 v-if="config && config.title != undefined && config.title != '' && config.title[lg] != ''"
        class="text-left text-lg  px-4 font-bold uppercase">
        {{ config.title && config.title[lg] }}
      </h6>

      <div v-if="config != undefined" class="flex flex-wrap p-3      ">
        <div v-for="zone in config?.items" :key="zone.id"
          class="relative  px-1   border-4   flex flex-wrap items-strech  flex-wrap" :class=" (zone.class)">
          <button v-if="isDeveloper == true" :id="`zone-${zone.id}-dropdown-button`"
            :data-dropdown-toggle="`zone-${zone.id}-dropdown`"
            class="absolute -top-5 right-1  bg-green-300 flex cursor-pointer text-gray-500  inline-flex items-center text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-700 p-1.5 dark:hover-bg-gray-800 text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
            type="button">
            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
          </button>
          <div :id="`zone-${zone.id}-dropdown`"
            class="hidden z-40 w-44 bg-gray-50 rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
            <ul class="py-1 text-sm" :aria-labelledby="`zone-${zone.id}-dropdown-button`">
              <li>
                <button type="button"
                  class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-700 dark:text-gray-200"
                  @click="manageZone(zone)">
                  <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                  </svg>
                  Modifier
                </button>
              </li>
              <li>
                <button type="button"
                  class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-700 dark:text-gray-200"
                  @click="addZone(zone, config.id)">
                  <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  Ajouter une zone
                </button>
              </li>
              <li>
                <button :id="`dropdownRightButtonAddElementInSection-${zone.id}`" type="button"
                  :data-dropdown-toggle="`dropdownRightAddElementInSection-${zone.id}`" data-dropdown-placement="right"
                  class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-700 dark:text-gray-200">
                  <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  Ajouter un élément
                </button>
              </li>
              <li>
                <button type="button"
                  class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 text-red-500 dark:hover:text-red-400">
                  <svg class="w-4 h-4 mr-2" viewbox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true">
                    <path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor"
                      d="M6.09922 0.300781C5.93212 0.30087 5.76835 0.347476 5.62625 0.435378C5.48414 0.523281 5.36931 0.649009 5.29462 0.798481L4.64302 2.10078H1.59922C1.36052 2.10078 1.13161 2.1956 0.962823 2.36439C0.79404 2.53317 0.699219 2.76209 0.699219 3.00078C0.699219 3.23948 0.79404 3.46839 0.962823 3.63718C1.13161 3.80596 1.36052 3.90078 1.59922 3.90078V12.9008C1.59922 13.3782 1.78886 13.836 2.12643 14.1736C2.46399 14.5111 2.92183 14.7008 3.39922 14.7008H10.5992C11.0766 14.7008 11.5344 14.5111 11.872 14.1736C12.2096 13.836 12.3992 13.3782 12.3992 12.9008V3.90078C12.6379 3.90078 12.8668 3.80596 13.0356 3.63718C13.2044 3.46839 13.2992 3.23948 13.2992 3.00078C13.2992 2.76209 13.2044 2.53317 13.0356 2.36439C12.8668 2.1956 12.6379 2.10078 12.3992 2.10078H9.35542L8.70382 0.798481C8.62913 0.649009 8.5143 0.523281 8.37219 0.435378C8.23009 0.347476 8.06631 0.30087 7.89922 0.300781H6.09922ZM4.29922 5.70078C4.29922 5.46209 4.39404 5.23317 4.56282 5.06439C4.73161 4.8956 4.96052 4.80078 5.19922 4.80078C5.43791 4.80078 5.66683 4.8956 5.83561 5.06439C6.0044 5.23317 6.09922 5.46209 6.09922 5.70078V11.1008C6.09922 11.3395 6.0044 11.5684 5.83561 11.7372C5.66683 11.906 5.43791 12.0008 5.19922 12.0008C4.96052 12.0008 4.73161 11.906 4.56282 11.7372C4.39404 11.5684 4.29922 11.3395 4.29922 11.1008V5.70078ZM8.79922 4.80078C8.56052 4.80078 8.33161 4.8956 8.16282 5.06439C7.99404 5.23317 7.89922 5.46209 7.89922 5.70078V11.1008C7.89922 11.3395 7.99404 11.5684 8.16282 11.7372C8.33161 11.906 8.56052 12.0008 8.79922 12.0008C9.03791 12.0008 9.26683 11.906 9.43561 11.7372C9.6044 11.5684 9.69922 11.3395 9.69922 11.1008V5.70078C9.69922 5.46209 9.6044 5.23317 9.43561 5.06439C9.26683 4.8956 9.03791 4.80078 8.79922 4.80078Z" />
                  </svg>
                  Supprimer
                </button>
              </li>
            </ul>
          </div>
          <!-- Dropdown menu -->
          <div :id="`dropdownRightAddElementInSection-${zone.id}`"
            class="z-40 hidden bg-gray-50 divide-y divide-gray-100  shadow w-44 dark:bg-gray-700">
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200 text-left"
              :aria-labelledby="`dropdownRightButtonAddElement-${zone.id}`">
              <li class="cursor-pointer">
                <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  @click="addFieldInZone(zone, 'text')">
                  Champ Texte
                </div>
              </li>
              <li class="cursor-pointer">
                <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  @click="addFieldInZone(zone, 'select')">
                  Selection Liste finie
                </div>
              </li>
              <li class="cursor-pointer">
                <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  @click="addFieldInZone(zone, 'image-upload')">
                  Chargement Image
                </div>
              </li>
              <li class="cursor-pointer">
                <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  @click="addFieldInZone(zone, 'image-display')">
                  Affichage Image
                </div>
              </li>
            </ul>
          </div>
          <div class=" p-3 w-full    flex flex-wrap  items-baseline2 items-start content-start ">
            <h6 v-if="zone && zone.title != undefined && zone.title != ''"
              class="block text-left w-full    text-lg  text-gray-500 mt-3 mb-2  font-bold uppercase">
              {{ zone.title && zone.title[lg] }}
            </h6>

            <div v-for="item in zone?.items" :key="item?.id" class="relative flex flex-wrap content-start px-2 py-0"
              :class="componentClass(item, record)">
              <button v-if="isDeveloper == true" :id="`field-${item.id}-dropdown-button`"
                :data-dropdown-toggle="`field-${item.id}-dropdown`"
                class="  z-10 absolute top-1 right-1   flex cursor-pointer text-gray-500 bg-blue-300  inline-flex items-center text-sm font-medium hover:bg-gray-100   p-1.5   text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
                type="button">
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                </svg>
              </button>
              <div :id="`field-${item.id}-dropdown`"
                class="hidden z-40   bg-gray-50 rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                <ul class="py-1 text-sm" :aria-labelledby="`field-${item.id}-dropdown-button`">
                  <li>
                    <button type="button"
                      class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-700 dark:text-gray-200"
                      @click="manageField(item)">
                      <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                      </svg>
                      Modifier
                    </button>
                  </li>

                  <li>
                    <button :id="`dropdownRightButtonAddElement-${item.id}`" type="button"
                      :data-dropdown-toggle="`dropdownRightAddElement-${item.id}`" data-dropdown-placement="right"
                      class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-700 dark:text-gray-200">
                      <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                      Ajouter un élément
                      <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 6 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="m1 9 4-4-4-4" />
                      </svg>
                    </button>
                  </li>
                  <li>
                    <button type="button"
                      class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 text-red-500 dark:hover:text-red-400"
                      @click="deleteField(item)">
                      <svg class="w-4 h-4 mr-2" viewbox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true">
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor"
                          d="M6.09922 0.300781C5.93212 0.30087 5.76835 0.347476 5.62625 0.435378C5.48414 0.523281 5.36931 0.649009 5.29462 0.798481L4.64302 2.10078H1.59922C1.36052 2.10078 1.13161 2.1956 0.962823 2.36439C0.79404 2.53317 0.699219 2.76209 0.699219 3.00078C0.699219 3.23948 0.79404 3.46839 0.962823 3.63718C1.13161 3.80596 1.36052 3.90078 1.59922 3.90078V12.9008C1.59922 13.3782 1.78886 13.836 2.12643 14.1736C2.46399 14.5111 2.92183 14.7008 3.39922 14.7008H10.5992C11.0766 14.7008 11.5344 14.5111 11.872 14.1736C12.2096 13.836 12.3992 13.3782 12.3992 12.9008V3.90078C12.6379 3.90078 12.8668 3.80596 13.0356 3.63718C13.2044 3.46839 13.2992 3.23948 13.2992 3.00078C13.2992 2.76209 13.2044 2.53317 13.0356 2.36439C12.8668 2.1956 12.6379 2.10078 12.3992 2.10078H9.35542L8.70382 0.798481C8.62913 0.649009 8.5143 0.523281 8.37219 0.435378C8.23009 0.347476 8.06631 0.30087 7.89922 0.300781H6.09922ZM4.29922 5.70078C4.29922 5.46209 4.39404 5.23317 4.56282 5.06439C4.73161 4.8956 4.96052 4.80078 5.19922 4.80078C5.43791 4.80078 5.66683 4.8956 5.83561 5.06439C6.0044 5.23317 6.09922 5.46209 6.09922 5.70078V11.1008C6.09922 11.3395 6.0044 11.5684 5.83561 11.7372C5.66683 11.906 5.43791 12.0008 5.19922 12.0008C4.96052 12.0008 4.73161 11.906 4.56282 11.7372C4.39404 11.5684 4.29922 11.3395 4.29922 11.1008V5.70078ZM8.79922 4.80078C8.56052 4.80078 8.33161 4.8956 8.16282 5.06439C7.99404 5.23317 7.89922 5.46209 7.89922 5.70078V11.1008C7.89922 11.3395 7.99404 11.5684 8.16282 11.7372C8.33161 11.906 8.56052 12.0008 8.79922 12.0008C9.03791 12.0008 9.26683 11.906 9.43561 11.7372C9.6044 11.5684 9.69922 11.3395 9.69922 11.1008V5.70078C9.69922 5.46209 9.6044 5.23317 9.43561 5.06439C9.26683 4.8956 9.03791 4.80078 8.79922 4.80078Z" />
                      </svg>
                      Supprimer
                    </button>
                  </li>
                </ul>
              </div>

              <!-- Dropdown menu -->
              <div :id="`dropdownRightAddElement-${item.id}`"
                class="z-40 hidden bg-gray-50 divide-y divide-gray-100  shadow w-44 dark:bg-gray-700">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200 text-left"
                  :aria-labelledby="`dropdownRightButtonAddElement-${item.id}`">
                  <li class="cursor-pointer">
                    <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      @click="addField(item, 'text')">
                      Champ Texte
                    </div>
                  </li>
                  <li class="cursor-pointer">
                    <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      @click="addField(item, 'blank')">
                      Zone vide
                    </div>
                  </li>
                  <li class="cursor-pointer">
                    <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      @click="addField(item, 'select')">
                      Selection Liste finie
                    </div>
                  </li>

                  <li class="cursor-pointer">
                    <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      @click="addField(item, 'selectCollection')">
                      Selection Liste avec Source
                    </div>
                  </li>


                  <li class="cursor-pointer">
                    <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      @click="addField(item, 'tag')">
                      Selection Tag
                    </div>
                  </li>


                  <li class="cursor-pointer">
                    <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      @click="addField(item, 'image-upload')">
                      Chargement Image
                    </div>
                  </li>
                  <li class="cursor-pointer">
                    <div class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      @click="addField(item, 'display')">
                      Affichage champ status
                    </div>
                  </li>
                </ul>
              </div>


              <ContentInput v-if="item.type=='text'" :id="`content-text-${zone.id}-${item.id}`"
                :ref="`content-text-${zone.id}-${item.id}`" :record="record" :config="item" :countries="countries"
                :label="item.label && item.label[lg]" :is-button="item.isButton"
                :placeholder="item.placeholder && item.placeholder[lg]" :value="buildValue(item.data)"
                @action="onAction" @update-field="configFieldChange" @value-changed="onValueChanged"
                @manage-field="manageField" />

              <ContentSelect v-if="item.type=='select'" :ref="`content-select-${zone.id}-${item.id}`"
                :label="item.label && item.label[lg]" :source="item.source" :mode="item.mode" :filters="item.filters"
                :placeholder="item.placeholder && item.placeholder[lg]" :value="record[item.data]" :choices="samples"
                :config="item" @manage-field="manageField" @update-field-value="updateFieldValue" />

              <ContentImageUploadNew v-if="item.type=='image-upload'" :id="`${item.id}-image-upload`"
                :ref="`content-image-upload-${zone.id}-${item.id}`" :data="item.data" :source="record[item.data]"
                :label="item.label && item.label[lg]" :config="item" @select-image-modal-open="OnSelectImageModalOpen"
                @manage-field="manageField" />

              <ContentSelectCollection v-if="item.type=='selectCollection'"
                :ref="`content-selectCollection-${zone.id}-${item.id}`" :custom-action="customAction"
                :label="item.label && item.label[lg]" :datasource="item.datasource_id" :mode="item.mode"
                :record="record" :filters="item.filters" :config="item" :value="record[item.data]" :choices="samples"
                @manage-field="manageField" @value-changed="onValueChanged" />


              <ContentSelectCheck v-if="item.type=='selectCheck'" :ref="`content-selectCheck-${zone.id}-${item.id}`"
                :label="item.label && item.label[lg]" :datasource="item.datasource" :collection="item.collection"
                :mode="item.mode" :filters="item.filters" :config="item"
                :placeholder="item.placeholder && item.placeholder[lg]" :value="record[item.data]" :choices="samples"
                @manage-field="manageField" @value-changed="onValueChanged" />


              <ContentSelectRadio v-if="item.type=='select-radio'" :ref="`content-select-radio-${zone.id}-${item.id}`"
                :label="item.label && item.label[lg]" :datasource="item.datasource" :collection="item.collection"
                :mode="item.mode" :filters="item.filters" :config="item"
                :placeholder="item.placeholder && item.placeholder[lg]" :value="record[item.data]" :choices="samples"
                @manage-field="manageField" @value-changed="onValueChanged" />




              <ContentGridSelect v-if="item.type=='grid-select'" :ref="`content-grid-select-${zone.id}-${item.id}`"
                :label="item.label && item.label[lg]" :collection="item.collection" :datasource="item.datasource"
                :mode="item.mode" :filters="item.filters" :config="item"
                :placeholder="item.placeholder && item.placeholder[lg]" :value="record[item.data]" :choices="samples"
                @manage-field="manageField" @value-changed="onValueChanged" />



              <ContentTag v-if="item.type=='tag'" :ref="`content-tag-${zone.id}-${item.id}`"
                :label="item.label && item.label[lg]" :placeholder=" item.placeholder && item.placeholder[lg]"
                :value="buildValue(item.data)" :config="item" @manage-field="manageField" />


              <ContentTinyTable v-if="item.type=='tiny-table'" :id="`content-tiny-table-${zone.id}-${item.id}`"
                :ref="`content-tiny-table-${zone.id}-${item.id}`" :is-button="item.isButton"
                :label="item.label && item.label[lg]" :model="item.model" :config="item"
                :config-table="configTable[item.id]" :records="getTableRecords(item.id,item.values,record[item.data])"
                :values="record[item.data]" @manage-field="manageField" @manage-postal-address="managePostalAddress"
                @manage-button-click="manageButtonClick" />

              <ContentDisplay v-if="item.type=='display'" :ref="`content-display-${zone.id}-${item.id}`"
                :label="item.label && item.label[lg]" :type="item.format" :col="item" :record="record"
                :value="record[item.data]" :config="item" @manage-field="manageField" />


              <ContentEditor v-if="item.type=='note'" :ref="`content-note-${zone.id}-${item.id}`"
                :label="item.label && item.label[lg]" :multilangual="item.multilangual " :config="item"
                :placeholder="item.placeholder && item.placeholder[lg]"
                :value="ForceMultiLangage(buildValue(item.data))" @manage-field="manageField" />



              <ContentStep v-if="item.type=='step'" :id="`content-text-${zone.id}-${item.id}`"
                :ref="`content-date-${zone.id}-${item.id}`" :label="item.label && item.label[lg]"
                :placeholder="item.placeholder && item.placeholder[lg]" :value="record[item.data]" :config="item"
                @manage-field="manageField" />



              <ContentButton v-if="item.type=='button'" :id="`content-button-${zone.id}-${item.id}`"
                :ref="`content-button-${zone.id}-${item.id}`" :config="item" :label="item.label && item.label[lg]"
                @update-field="configFieldChange" @action="onAction" @manage-field="manageField" />


              <div class="w-full ">
                <ContentButtonSelect v-if="item.type=='button-select'"
                  :id="`content-button-select-${zone.id}-${item.id}`"
                  :ref="`content-button-select-${zone.id}-${item.id}`" :config="item"
                  :label="item.label && item.label[lg]" :placeholder="item.placeholder && item.placeholder[lg]"
                  :value="buildValue(item.data)" @update-field="configFieldChange" @value-changed="onValueChanged"
                  @manage-field="manageField" />

                <ContentDate v-if="item.type=='date'" :id="`content-text-${zone.id}-${item.id}`"
                  :ref="`content-date-${zone.id}-${item.id}`" :label="item.label && item.label[lg]"
                  :placeholder="item.placeholder && item.placeholder[lg]" :value="record[item.data]" :config="item"
                  @manage-field="manageField" />


                <ContentDate v-if="item.type=='date-time'" :id="`content-text-${zone.id}-${item.id}`"
                  :ref="`content-date-${zone.id}-${item.id}`" :label="item.label && item.label[lg]"
                  :placeholder="item.placeholder && item.placeholder[lg]" :value="record[item.data]" :config="item"
                  @manage-field="manageField" />



                <ContentPDF v-if="item.type=='pdf'" :id="`content-text-${zone.id}-${item.id}`" :isActive="isActive"
                  :currentTab="currentTab" :indexTab="indexTab" :ref="`content-date-${zone.id}-${item.id}`"
                  :label="item.label && item.label[lg]" :placeholder="item.placeholder && item.placeholder[lg]"
                  :value="record[item.data]" :record="record" :config="item" @manage-field="manageField"
                  @action="onAction" />


                <ContentTimeline v-if="item.type=='timeline'" :id="`content-timeline-${zone.id}-${item.id}`"
                  :ref="`content-text-${zone.id}-${item.id}`" :label="item.label && item.label[lg]" :record="record"
                  :config="item" @manage-field="manageField" />


                <ContentImage v-if="item.type=='image'" :ref="`content-image-${zone.id}-${item.id}`"
                  :source="item.source" :data="getRecordValue(item?.data)" :record="record"
                  :label="item.label && item.label[lg]" :config="item" @manage-field="manageField" />


                <ContentImageGallery v-if="item.type=='image-gallery'" :id="item.id"
                  :ref="`content-image-${zone.id}-${item.id}`" :source="item.data" :img1="getRecordValue(item?.data1)"
                  :img2="getRecordValue(item?.data2)" :img3="getRecordValue(item?.data3)"
                  :img4="getRecordValue(item?.data4)" :img5="getRecordValue(item?.data5)"
                  :label="item.label && item.label[lg]" :config="item" @manage-field="manageField" />


                <ContentQRCode v-if="item.type=='qrcode'" :ref="`content-qrcode-${zone.id}-${item.id}`" :source="item"
                  :value="record" :config="item" @manage-field="manageField" />


                <ContentLabel v-if="item.type=='label'" :ref="`content-label-${zone.id}-${item.id}`" :source="item"
                  :value="record" :config="item" @manage-field="manageField" />

                <ContentComposer v-if="item.type=='composer'" :id="`content-composer-${zone.id}-${item.id}`"
                  :ref="`content-composer-${zone.id}-${item.id}`" :value="record[item.data]" :config="item"
                  @manage-field="manageField" />


                <ContentRecipeComposer v-if="item.type=='recipe-composer'"
                  :id="`content-recipe-composer-${zone.id}-${item.id}`"
                  :ref="`content-recipe-composer-${zone.id}-${item.id}`" :value="record[item.data]"
                  :supplier="item.suppliers" :parent="record[item.parent]" :volume="record[item.volume]"
                  :config="item" />




                <ContentRecipeComposerNew v-if="item.type=='recipe-step-composer'"
                  :id="`content-recipe-step-composer-${zone.id}-${item.id}`"
                  :ref="`content-recipe-step-composer-${zone.id}-${item.id}`" :value="record[item.data]"
                  :parent="record[item.parent]" :config="item" />


                <ContentPackagingComposer v-if="item.type=='packaging-composer'"
                  :id="`content-packaging-composer-${zone.id}-${item.id}`"
                  :ref="`content-packaging-composer-${zone.id}-${item.id}`" :value="record[item.data]"
                  :parent="record[item.parent]" :volume="record[item.volume]" :config="item"
                  @manage-field="manageField" />


                <ContentIngredientComposer v-if="item.type=='ingredient-composer'"
                  :id="`content-ingredient-composer-${zone.id}-${item.id}`"
                  :ref="`content-ingredient-composer-${zone.id}-${item.id}`" :value="record[item.data]"
                  :parent="record[item.parent]" :volume="record[item.volume]" :config="item"
                  @manage-field="manageField" />

                <ContentList v-if="item.type=='list'" :ref="`content-list-${zone.id}-${item.id}`" :config="item"
                  :source="item.source" @manage-field="manageField" />

                <ContentListPackaging v-if="item.type=='list-packaging'"
                  :ref="`content-list-packaging-${zone.id}-${item.id}`" :source="item.source" :value="record[item.data]"
                  :config="item" @manage-field="manageField" />

                <ContentFileList v-if="item.type=='file-list'" :id="`${item.id}-file-list`"
                  :ref="`content-file-list-${zone.id}-${item.id}`" :value="record[item.data]" :category="item.category"
                  :config="item" @manage-field="manageField" />


                <ContentFileUpload v-if="item.type=='file-upload'" :id="`${item.id}-file-upload`"
                  :ref="`content-file-upload-${zone.id}-${item.id}`" :value="record[item.data]" :source="item.source"
                  :label="item.label && item.label[lg]" :config="item" @manage-field="manageField" />

                <ContentFileUploadExtended v-if="item.type=='file-upload-extended'" :id="`${item.id}-file-upload`"
                  :ref="`content-file-upload-${zone.id}-${item.id}`" :value="record[item.data]" show-icons="true"
                  :source="item.source" :label="item.label && item.label[lg]"
                  :display-expiration="item.displayExpiration" :config="item" @manage-field="manageField" />


                <ContentImageUpload v-if="item.type=='image-upload-old'" :id="`${item.id}-image-upload`"
                  :ref="`content-image-upload-${zone.id}-${item.id}`" :source="record[item.data]"
                  :label="item.label && item.label[lg]" :config="item" @manage-field="manageField" />



                <ContentNutriscore v-if="item.type=='nutriscore'" :ref="`content-display-${zone.id}-${item.id}`"
                  :label="item.label && item.label[lg]" :type="item.format" :mode="item.mode" :col="item"
                  :record="record" :facts="record[item.data]" :config="item" />


                <ContentAcordeon v-if="item.type=='acordeon'" :ref="`content-title-${zone.id}-${item.id}`"
                  :label="item.label && item.label[lg]" :config="item" :value="record[item.data]"
                  :placeholder="item.placeholder && item.placeholder[lg]" @manage-field="manageField" />



                  <ContentChartInForm v-if="item.type=='chart'" :ref="`content-chart-${zone.id}-${item.id}`"
                  :label="item.label && item.label[lg]" :config="item"
                  :placeholder="item.placeholder && item.placeholder[lg]" @manage-field="manageField" />


                <ContentTitle v-if="item.type=='title'" :ref="`content-title-${zone.id}-${item.id}`"
                  :label="item.label && item.label[lg]" :config="item"
                  :placeholder="item.placeholder && item.placeholder[lg]" @manage-field="manageField" />


                <ContentCompliant v-if="item.type=='compliant'" :ref="`content-comliant-${zone.id}-${item.id}`"
                  :label="item.label && item.label[lg]" :source="record[item.data]" :comment="record[item.dataEx]"
                  :value="record[item.dataValue]" :config="item" :file="record[item.dataFile]"
                  @manage-field="manageField" />

                <ContentControls v-if="item.type=='controls'" :ref="`content-comliant-${zone.id}-${item.id}`"
                  :label="item.label && item.label[lg]" :source="record[item.data]" :comment="record[item.dataEx]"
                  :value="record[item.dataValue]" :config="item" :file="record[item.dataFile]"
                  @manage-field="manageField" />

                <ContentMessage v-if="item.type=='message'" :ref="`content-message-${zone.id}-${item.id}`"
                  :config="item" :label="item.label && item.label[lg]" :value="record[item.data]"
                  :messages="item.messages" :comment="record[item.comment]" @manage-field="manageField" />

                <ContentTableSwitch v-if="item.type=='table-switch'" :ref="`content-table-switch-${zone.id}-${item.id}`"
                  :label="item.label && item.label[lg]" :value="buildBooleanValue(item.data, item.default)"
                  :placeholder=" item.placeholder && item.placeholder[lg]" :config="item" @manage-field="manageField" />



                <ContentSwitch v-if="item.type=='switch'" :ref="`content-switch-${zone.id}-${item.id}`"
                  :label="item.label && item.label[lg]" :value="buildBooleanValue(item.data, item.default)"
                  :placeholder=" item.placeholder && item.placeholder[lg]" :config="item" @manage-field="manageField"
                  @update-field-value="updateFieldValue" />

                <ContentRadio v-if="item.type=='radio'" :ref="`content-radio-${zone.id}-${item.id}`"
                  :label="item.label && item.label[lg]" :placeholder="item.placeholder && item.placeholder[lg]"
                  value="record[item.data]" :config="item" @manage-field="manageField" />
              </div>
              <B4FTable
                v-if="item.type=='table' && configTable[item.id] != undefined && recordsTable[item.id] != undefined"
                :ref="`content-table-${zone.id}-${item.id}`" :limit="limit" :offset="offset" :class="item.class"
                :config="configTable[item.id]" :records="recordsTable[item.id]" @row-click="onRowClick" />
              <div v-if="item.type=='table' && recordsTable[item.id] != undefined " class="hidden">
                {{ configTable }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import engine from "@/lib/engine.js"
  const MAX_ITEMS = 10;

  import B4FTable from '@/components/TableComponent.vue'

  import ContentButton from '@/components/content/ContentButton.vue'

  import ContentButtonSelect from '@/components/content/ContentButtonSelect.vue'

  import ContentInput from '@/components/content/ContentInput.vue'
  import ContentTitle from '@/components/content/ContentTitle.vue'
  import ContentAcordeon from '@/components/content/ContentAcordeon.vue'
  import ContentChartInForm from '@/components/content/ContentChartInForm.vue'


  import ContentCompliant from '@/components/content/ContentCompliant.vue'

  import ContentControls from '@/components/content/ContentControls.vue'


  import ContentMessage from '@/components/content/ContentMessage.vue'
  import ContentTinyTable from '@/components/content/ContentTinyTable.vue'
  import ContentTag from '@/components/content/ContentTag.vue'
  import ContentDisplay from '@/components/content/ContentDisplay.vue'
  import ContentStep from '@/components/content/ContentStep.vue'
  import ContentImage from '@/components/content/ContentImage.vue'
  import ContentImageGallery from '@/components/content/ContentImageGallery.vue'
  import ContentFileUpload from '@/components/content/ContentFileUpload.vue'
  import ContentFileUploadExtended from '@/components/content/ContentFileUploadExtended.vue'
  import ContentImageUploadNew from '@/components/content/ContentImageUploadNew.vue'
  import ContentImageUpload from '@/components/content/ContentImageUpload.vue'
  import ContentSelect from '@/components/content/ContentSelect.vue'
  import ContentSelectCollection from '@/components/content/ContentSelectCollection.vue'
  import ContentSelectCheck from '@/components/content/ContentSelectCheck.vue'
  import ContentSelectRadio from '@/components/content/ContentSelectRadio.vue'

  import ContentGridSelect from '@/components/content/ContentGridSelect.vue'

  import ContentEditor from '@/components/content/ContentEditor.vue'
  import ContentSwitch from '@/components/content/ContentSwitch.vue'
  import ContentTableSwitch from '@/components/content/ContentTableSwitch.vue'
  import ContentRadio from '@/components/content/ContentRadio.vue'
  import ContentDate from '@/components/content/ContentDate.vue'
  import ContentPDF from '@/components/content/ContentPdf.vue'
  import ContentQRCode from '@/components/content/ContentQRCode.vue'
  import ContentLabel from '@/components/content/ContentLabel.vue'
  import ContentTimeline from '@/components/content/ContentTimeline.vue'
  import ContentComposer from '@/components/content/ContentComposer.vue'
  import ContentNutriscore from '@/components/content/ContentNutriscore.vue'
  import ContentRecipeComposer from '@/components/content/ContentRecipeComposer.vue'
  import ContentRecipeComposerNew from '@/components/content/ContentRecipeComposerNew.vue'

  import ContentPackagingComposer from '@/components/content/ContentPackagingComposer.vue'
  import ContentIngredientComposer from '@/components/content/ContentIngredientComposer.vue'
  import ContentList from '@/components/content/ContentList.vue'
  import ContentListPackaging from '@/components/content/ContentListPackaging.vue'
  import ContentFileList from '@/components/content/ContentFileList.vue'
  import service from "@/services/FullService.vue"
  import { mapGetters } from "vuex";
  import Handlebars from "handlebars";
  import { flatten } from 'flat'
  import { Dropdown } from 'flowbite';

  export default {
    name: 'ContentComponent',

    components: { ContentChartInForm, ContentNutriscore, B4FTable, ContentStep, ContentPDF, ContentMessage, ContentTinyTable, ContentIngredientComposer, ContentPackagingComposer, ContentRecipeComposer, ContentRecipeComposerNew, ContentComposer, ContentTag, ContentDisplay, ContentCompliant, ContentControls, ContentTitle, ContentAcordeon, ContentFileUploadExtended, ContentButtonSelect, ContentButton, ContentInput, ContentListPackaging, ContentList, ContentTimeline, ContentFileList, ContentEditor, ContentGridSelect, ContentSelect, ContentSelectCollection, ContentSelectCheck, ContentSelectRadio, ContentSwitch, ContentTableSwitch, ContentImage, ContentImageGallery, ContentFileUpload, ContentImageUpload, ContentImageUploadNew, ContentRadio, ContentDate, ContentQRCode, ContentLabel },


    props: {
      id: String,
      config: Object,
      record: Object,
      countries: Array,
      isActive: Boolean,

      currentTab: Number,
      indexTab: Number,


      settings: Object,
      customAction: Object,
    },

    data() {
      return {
        lg: "FR",
        samples: [],
        output: [],
        limit: MAX_ITEMS,
        offset: 0,
        configTable: {},
        recordFlatten: {},
        recordsTable: {}

      }
    },

    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },

      isDeveloper: function () {
        return this.user?.isDeveloper;
      },
    },


    watch: {
      "record": async function () {


        //   await this.loadAdditionalRecords();


      },
      "settings": async function () {
        //     console.log("CONTENT COMPONENT - watch settings")


      }

    },


    async mounted() {
      if (this.user == undefined) {
        //this.$router.push("/login");
        window.location = "/login"
        return;
      }
      this.isLoading = true;

      if (this.settings?.body != undefined) {

        for (let y = 0; y < this.settings.body?.length; y++) {
          let item = this.settings.body[y];
          if (item.items != undefined) {
            for (let yy = 0; yy < item.items.length; yy++) {
              let section = item.items[yy]
              if (section.items != undefined) {

                for (let yyy = 0; yyy < section.items.length; yyy++) {

                  let block = section.items[yyy]

                  if (block?.id != undefined) {

                    if (block.data != undefined) {

                      if (this.recordsTable == undefined) this.recordsTable = {}

                      if (this.record != undefined) {
                        try {
                          this.recordsTable[block.data] = { records: this.record[block.data] }
                        }
                        catch (e) {
                          this.recordsTable[block.data] = { records: [] }
                        }

                      }

                      // await this.loadAdditionalRecords();

                    }
                    if (block.source_id != undefined) {
                      if (block.filter == undefined) block.filter = {}


                      let keys_filter = Object.keys(block.filter)
                      let context =
                      {
                        id: this.$route.params.rid,
                        _id: this.$route.params.rid
                      }


                      for (let k = 0; k < keys_filter.length; k++) {
                        const template = Handlebars.compile(block.filter[keys_filter[k]]);
                        if (context != undefined)
                          block.filter[keys_filter[k]] = (template(context));
                      }



                      let limit = 100;
                      let offset = 0
                      if (block?.source_id != undefined)
                        this.recordsTable[block.id] = await service.getData("data-sources/" + block?.source_id + '/records', this.user?.token, Object.assign({}, { limit: limit, offset: offset }, block.filter))

                    }

                    this.configTable[block.id] = await service.getConfiguration(block.schema, "a", this.user?.token)

                  }

                }


              }
            }


          }



        }

      }
      if (this.record != undefined)
        this.recordFlatten = flatten(this.record)

      else
        this.recordFlatten = "[]"
      //   await  this.loadAdditionalRecords();

    },
    methods: {
      async loadAdditionalRecords() {

        if (this.record != undefined)
          this.recordFlatten = flatten(this.record)
        else
          this.recordFlatten = "[]"

        for (let y = 0; y < this.settings?.body?.length; y++) {
          let item = this.settings?.body[y];
          for (let yy = 0; yy < item?.items?.length; yy++) {
            let section = item?.items[yy]
            for (let yyy = 0; yyy < section?.items?.length; yyy++) {

              let block = section.items[yyy]

              if (block?.id != undefined) {

                if (block?.data != undefined) {
                  this.recordsTable[block.id] = { records: this.record[block.data] }
                }
                else {

                  if (block.collection != undefined) {

                    let context =
                    {
                      id: this.$route.params.rid,
                      _id: this.$route.params.rid
                    }


                    if (block.filter == undefined) block.filter = {}


                    let keys_filter = Object.keys(block.filter)


                    for (let k = 0; k < keys_filter.length; k++) {
                      const template = Handlebars.compile(block.filter[keys_filter[k]]);
                      if (context != undefined)
                        block.filter[keys_filter[k]] = (template(context));
                    }



                    let limit = MAX_ITEMS;
                    let offset = 0
                    if (block?.source_id != undefined)
                      this.recordsTable[block.id] = await service.getData("data-sources/" + block?.source_id + '/records', this.user?.token, Object.assign({}, { limit: limit, offset: offset }, block.filter))
                    //PVBATCH
                    if (block?.data != undefined) {
                      this.recordsTable[block.id] = this.record['batches']

                    }

                    //   this.recordsTable[block.id] = await service.getData(block.collection, this.user?.token, block.filter)
                  }
                  // else {
                  //     this.recordsTable[block.id] = []

                  // }

                }

                this.configTable[block.id] = await service.getConfiguration(block.schema, "a", this.user?.token)

              }

            }

          }

        }
      },



      getTableRecords(id, att, data) {

        if (data != undefined) return data;


        if (this.recordsTable[id] == undefined) return []
        return this.recordsTable[id].records;
      },
      updateFieldValue(event) {

        ///   console.log(event)
        //   console.log(this.record[event.id], " = ", event.value)
        if (this.record[event.id] != event.value) {
          this.$emit("valueChanged", event)
          //  this.record[event.data] = event.value
        }
      },

      componentClass(item, data) {
        //   console.log(data)
        //  if (data == undefined) return true;
        //   console.log(item.class)
        if (item.visible != null) {
          // console.log("ContentComponent ", "isVisible", JSON.stringify(item.visible))
          let e = new engine(data, item.visible)
          let c = e.check()
          console.log("ContentComponent ", data.product_packaging_type, JSON.stringify(c))
          if (c.length > 0) return 'hidden'
        }
        return item.class
      },

      fixZoneClass(cl) {
        if (cl == undefined) return "w-full flex-col"
        return cl;
      },
      ForceMultiLangage: function (text) {
        if (typeof text == "object") return text

        return { fr: text, en: '', sp: '', de: '', it: '' }
      },


      getRecordValue: function (field) {

        if (field == undefined) return null
        if (this.recordFlatten == undefined) return null


        //const template = Handlebars.compile(field);
        //                                    return   (template(this.record));
        return this.recordFlatten[field];
      },

      addFieldInZone: function (config, type) {

        let id = "field-" + config.id + "-dropdown";
        let idbutton = "field-" + config.id + "-dropdown-button";

        const $targetEl = document.getElementById(id);
        const $triggerEl = document.getElementById(idbutton);

        if ($triggerEl != undefined) {

          let dropdown = new Dropdown($targetEl, $triggerEl, this.options);
          dropdown.hide()
        }
        this.$emit("addField", { inside: config.id, after: null, type: type })
      },


      addField: function (config, type) {

        let id = "field-" + config.id + "-dropdown";
        let idbutton = "field-" + config.id + "-dropdown-button";

        const $targetEl = document.getElementById(id);
        const $triggerEl = document.getElementById(idbutton);

        if ($triggerEl != undefined) {

          let dropdown = new Dropdown($targetEl, $triggerEl, this.options);
          dropdown.hide()
        }
        this.$emit("addField", { inside: null, after: config, type: type })
      },
      manageField: function (config) {
        let id = "field-" + config.id + "-dropdown";
        let idbutton = "field-" + config.id + "-dropdown-button";

        const $targetEl = document.getElementById(id);
        const $triggerEl = document.getElementById(idbutton);

        if ($triggerEl != undefined) {

          let dropdown = new Dropdown($targetEl, $triggerEl, this.options);
          dropdown.hide()
        }
        this.$emit("manageField", config)
      },


      deleteField: function (config) {
        this.$emit("deleteField", config)
      },


      deleteZone: function (config) {

        let id = "zone-" + config.id + "-dropdown";
        let idbutton = "zone-" + config.id + "-dropdown-button";

        const $targetEl = document.getElementById(id);
        const $triggerEl = document.getElementById(idbutton);

        if ($triggerEl != undefined) {

          let dropdown = new Dropdown($targetEl, $triggerEl, this.options);
          dropdown.hide()
        }



        this.$emit("delete", { id: this.config.id, type: this.config.type })

      },

      addZone: function (config, section) {

        let id = "zone-" + config.id + "-dropdown";
        let idbutton = "zone-" + config.id + "-dropdown-button";

        const $targetEl = document.getElementById(id);
        const $triggerEl = document.getElementById(idbutton);

        if ($triggerEl != undefined) {

          let dropdown = new Dropdown($targetEl, $triggerEl, this.options);
          dropdown.hide()
        }

        let data =
        {
          id: 'new',
          after: config.id,
          section: section,
          tab: this.settings.id,
          type: "zone",
          label: {
            FR: "Nouvelle Zone",
            EN: "New Zone"
          },
          class: "bg-gray-100 text-gray-800 w-6/12"
        }


        this.$emit("manageField", data)

      },
      manageZone: function (config) {

        let id = "zone-" + config.id + "-dropdown";
        let idbutton = "zone-" + config.id + "-dropdown-button";

        const $targetEl = document.getElementById(id);
        const $triggerEl = document.getElementById(idbutton);

        if ($triggerEl != undefined) {

          let dropdown = new Dropdown($targetEl, $triggerEl, this.options);
          dropdown.hide()
        }

        let data =
        {
          id: config.id,
          previoud: config.id,
          type: "zone",
          label: {
            FR: config.title?.FR,
            EN: config.title?.EN
          },
          class: config.class != undefined ? config.class : ""
        }


        this.$emit("manageField", data)
      },
      addSection: function (config) {
        let id = "section-" + config.id + "-dropdown";
        let idbutton = "section-" + config.id + "-dropdown-button";

        const $targetEl = document.getElementById(id);
        const $triggerEl = document.getElementById(idbutton);

        if ($triggerEl != undefined) {

          let dropdown = new Dropdown($targetEl, $triggerEl, this.options);
          dropdown.hide()
        }
        //    let previousid = config.id;
        let data =
        {
          id: 'new',
          after: config.id,
          tab: this.settings.id,
          type: "section",
          label: {
            FR: "Nouvelle Section",
            EN: "New Section"
          },
          class: "bg-gray-100 text-gray-800 w-full"
        }

        this.$emit("manageField", data)

      },
      deleteSection: function (config) {
        let id = "section-" + config.id + "-dropdown";
        let idbutton = "section-" + config.id + "-dropdown-button";

        const $targetEl = document.getElementById(id);
        const $triggerEl = document.getElementById(idbutton);

        if ($triggerEl != undefined) {

          let dropdown = new Dropdown($targetEl, $triggerEl, this.options);
          dropdown.hide()
        }



        this.$emit("delete", { id: this.config.id, type: this.config.type })

      },
      manageSection: function (config) {

        let id = "section-" + config.id + "-dropdown";
        let idbutton = "section-" + config.id + "-dropdown-button";

        const $targetEl = document.getElementById(id);
        const $triggerEl = document.getElementById(idbutton);

        if ($triggerEl != undefined) {

          let dropdown = new Dropdown($targetEl, $triggerEl, this.options);
          dropdown.hide()
        }


        let data =
        {
          id: config.id,
          type: "section",
          label: {
            FR: config.title.FR,
            EN: config.title.EN
          },
          class: config.class != undefined ? config.class : ""
        }


        this.$emit("manageField", data)
      },
      manageButtonClick: function (field) {
        this.$emit("manageButtonClick", field)
      },
      managePostalAddress: function (field) {
        this.$emit("managePostalAddress", field)
      },
      configFieldChange: function (field) {
        this.$emit("changeFieldParameter", field)
      },
      OnSelectImageModalOpen: function (item) {
        this.$emit("selectImageModalOpen", item)
      },
      onAction: function (event) {
        // console.log(JSON.stringify(this.record))

        if (event.type == "send-invitation")
          event.email = this.record?.email

        if (event.type == "reset-password")
          event.email = this.record?.email

        if (event.type == 'activate-portal-user') {
          event.email = this.record?.email
          event.actor_id = this.record?._id
          event.first_name = this.record?.first_name
          event.last_name = this.record?.last_name
          event.login = this.record?.email
          event.role = 'collaborateur'
        }


        this.$emit("action", event)
        //this.$emit("valueChanged", event)
      },


      onValueChanged: function (event) {


        this.$emit("valueChanged", event)

      },

      onRowClick: function (item) {
        this.$emit("changeView", item)
      },
      buildValue(data) {

        if (this.record) {
          try {
            return eval("this.record." + data)
          }
          catch (e) {
            return ("")
          }
        }
        return ("")
      },


      buildBooleanValue(data, value) {

        if (this.record) {
          try {
            let v = eval("this.record." + data)

            return v
          }
          catch (e) {
            if (value == undefined) return true;
            return (value)
          }
        }
        return (value)
      },
      unflatten: function (data) {
        /* eslint-disable */
        if (Object(data) !== data || Array.isArray(data))
          return data;
        var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
          resultholder = {};
        for (var p in data) {
          var cur = resultholder,
            prop = "",
            m;
          while (m = regex.exec(p)) {
            cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
            prop = m[2] || m[1];
          }
          cur[prop] = data[p];
        }
        /* eslint-enable */
        return resultholder[""] || resultholder;
      },
      isObject: function (item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
      },
      mergeDeep: function (target, ...sources) {
        if (!sources) return target;
        if (!sources?.length) return target;
        const source = sources.shift();

        if (this.isObject(target) && this.isObject(source)) {
          for (const key in source) {
            if (this.isObject(source[key])) {
              if (!target[key]) Object.assign(target, { [key]: {} });
              this.mergeDeep(target[key], source[key]);
            } else {
              Object.assign(target, { [key]: source[key] });
            }
          }
        }
        return this.mergeDeep(target, ...sources);
      },


      isGetData: function (type, mode) {
        if (type == 'text') return true
        if (type == 'radio') return true
        if (type == 'select') return true
        if (type == 'selectCollection') return true
        if (type == 'selectCheck') return true
        if (type == 'select-radio') return true
        if (type == 'date') return true


        if (type == 'tag') return true
        if (type == 'file-upload-extended') return true

        if (type == 'button-select') return true
        if (type == 'grid-select') return true

        if (type == 'tiny-table') {
          return (mode == 'read-write')
        }

        if (type == 'composer') return true
        if (type == 'list-packaging') return true
        if (type == 'ingredient-composer') return true


        if (type == 'recipe-composer') return true

        if (type == 'recipe-step-composer') return true


        if (type == 'switch') return true
        if (type == 'table-switch') return true

        if (type == 'label') return true
        if (type == 'file-list') return true

        if (type == 'file-list') return true
        if (type == 'image-upload') return true
        if (type == 'note') return true

        return false;

      },


      getData: function () {
        console.log("getData ContentComponent ")
        let record = {}
        let isError = false;


        if (this.config && this.config.items)
          for (let i = 0; i < this.config?.items?.length; i++) {
            if (this.config.items[i].items != undefined)
              for (let j = 0; j < this.config.items[i].items.length; j++) {
                //if ((this.config.items[i].items[j].type == 'text')

                let ref = "content-" + this.config.items[i].items[j].type + "-" + this.config.items[i].id + "-" + this.config.items[i].items[j].id

                if (this.isGetData(this.config.items[i].items[j].type, this.config.items[i].items[j].mode)




            /*    (this.config.items[i].items[j].type == 'text')
                  || (this.config.items[i].items[j].type == 'radio')
                  || (this.config.items[i].items[j].type == 'select')
                  || (this.config.items[i].items[j].type == 'selectCollection')
                  || (this.config.items[i].items[j].type == 'selectCheck')
                  || (this.config.items[i].items[j].type == 'select-radio')
                  || (this.config.items[i].items[j].type == 'date')
                  || (this.config.items[i].items[j].type == 'tag')
                  || (this.config.items[i].items[j].type == 'file-upload-extended')
                  || (this.config.items[i].items[j].type == 'button-select')
                  || (this.config.items[i].items[j].type == 'grid-select')
                  || ((this.config.items[i].items[j].type == 'tiny-table') && (this.config.items[i].items[j].mode == 'read-write'))
                  || (this.config.items[i].items[j].type == 'composer')
                  || (this.config.items[i].items[j].type == 'list-packaging')
                  || (this.config.items[i].items[j].type == 'ingredient-composer')
                  || (this.config.items[i].items[j].type == 'recipe-composer')
                  || (this.config.items[i].items[j].type == 'recipe-step-composer')
                  || (this.config.items[i].items[j].type == 'switch')
                  || (this.config.items[i].items[j].type == 'table-switch')
                  || (this.config.items[i].items[j].type == 'label')
                  || (this.config.items[i].items[j].type == 'file-list')
                  || (this.config.items[i].items[j].type == 'image-upload')
                  || (this.config.items[i].items[j].type == 'note')*/) {
                  if (this.$refs[ref]) {

                   
                    for (let u = 0; u < this.$refs[ref]?.length; u++) {
                      if ((this.config.items[i].items[j].data == "value.FR") || ((this.config.items[i].items[j].data == "value.EN"))) {
                        let text = {};

                        let response = this.$refs[ref][u].getData()

                        if (response?.data == undefined)
                          text[this.config.items[i].items[j].data] = response
                        else {
                          isError = isError && response?.isError
                          text[this.config.items[i].items[j].data] = response?.data
                        }



                        console.log("!!!", response)

                        //        if (this.config.items[i].items[j].required == true) {
                        //          console.log("#.##", response, this.config.items[i].items[j])
                        //          return ({ data: record, isError: true });
                        //        }


                        let o = this.unflatten(text);
                        record = this.mergeDeep(o, record);
                        //  record = { ...record, ...o }
                      }
                      else {

                        let response = this.$refs[ref][u].getData()


                        let response_data = null

                        if (response?.data == undefined)
                          response_data = response
                        else
                          response_data = response.data


                        let data_label = this.config.items[i].items[j].data

                        if (data_label != undefined) {
                          if (data_label.indexOf(".") != -1) {
                            let flat_data = {}
                            flat_data[data_label] = response_data

                            let o = this.unflatten(flat_data);
                            record = this.mergeDeep(o, record);
                          }
                          else {
                            record[data_label] = response_data
                          }
                        }




                      //  console.log("!!!!", response, response?.isError, isError)





                        if (response?.isError != undefined)
                          isError = isError && response?.isError


                          console.log("required 11 = ", this.config.items[i].items[j].required, response_data)
                   

                        if (this.config.items[i].items[j].required == true) {

                          console.log("/////////////************************************", response_data,(response_data == undefined))
                       
                       
                       
                          if ((response_data == undefined) || (response_data == "")) {
                            console.log("#...##", response, this.config.items[i].items[j])
                            console.log(JSON.stringify(this.config.items[i].items[j]))
                            return ({ data: record, isError: true , label: this.config.items[i].items[j].label[this.lg] });
                          }
                        }

                        // if ((this.config.items[i].items[j].type == 'tiny-table')) {

                        //   }


                        //    record[this.config.items[i].items[j].data] = this.$refs[ref][u].getData()
                      }

                      //    if ((this.config.items[i].items[j].type == 'file-upload-extended')) {

                      //    }


                    }
                  }


                }

              }


          }

        console.log("//////////////////////////////////////////////////")

        console.log(JSON.stringify({ data: record, isError: isError }))

        return ({ data: record, isError: isError })
      }
    },

  }
</script>