<template2>
    <div class="relative   w-full  w-96  flex justify-center items-center p-2 ">
        <apexchart class=" h-auto  w-full flex items-center  justify-center   pl-3" type="treemap"
            :options="chartOptions" :series="series" />
    </div>
</template2>

<template>
    <div class="relative  w-full     flex justify-center items-center px-2   min-h-36">
        <apexchart class=" w-full " type="treemap" :options="chartOptions" :series="series" height="100%"
            @updated="onUpdate()" @mounted="onMounted()" :class="{'opacity-0' : !loaded, 'opacity-100': loaded }" />
    </div>
</template>


<style scoped>
    .chart {
        width: 100%;
        /* Prend toute la largeur du parent */
        height: 100%;
        /* Prend toute la hauteur du parent */
        max-width: 100%;
        /* Empêche le débordement horizontal */
        max-height: 100%;
        /* Empêche le débordement vertical */
        display: block;

        min-height: initial !important;
        /* Évite les comportements indésirables d'affichage */
    }

    .relative {
        position: relative;
        /* width: 100%; Occupe toute la largeur */
        /* height: ; Assure la hauteur complète */
        height: 100%;
        /* display: flex;Pour centrer les éléments */
        /* justify-content: center; */
        /* align-items: center; */
        overflow: hidden;
        /* Empêche tout débordement */
        /* Empêche le débordement du contenu */
    }
</style>


<script>
    import VueApexCharts from "vue3-apexcharts";
    export default {
        name: 'ChartTreeMap',
        components: {
            apexchart: VueApexCharts,
        },
        props: {

            title: String,
            records: Object,
            config: Object,
            refresh: String
        },
        data: function () {
            return {

                loaded: false,

                chartOptions: {
                    legend: {
                        show: true
                    },
                    chart: {

                        type: 'treemap',
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                                reset: false,
                                customIcons: []
                            },
                            export: {

                            },
                            autoSelected: 'zoom'
                        },


                    },
                    title: {
                        text: this.title
                    }
                },
                series: [
                    {
                        data: []
                    }
                ]
            }
        },
        watch: {
            'refresh': function () {
              //  console.log("RowBarChart WATCH records")
                if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
            },


            'records': async function (val) {
             //   console.log("TreeMapChart WATCH records")
             //   console.log("ColumnBarChart WATCH records")
                //    console.log("ColumnBarChart WATCH records")
                //    console.log("ColumnBarChart WATCH records")
             //   console.log("ColumnBarChart WATCH records")
             //   console.log(val)


                this.localrecords = val.records



                this.series[0].data = val.records;

            }


        },
        async mounted() {

            if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.id })
            window.addEventListener("resize", this.updateChartSize);


        },

        methods: {
            onUpdate() {
              //  console.log("onUpdate")
                this.loaded = true;
            },
            onMounted() {
              //  console.log("onMounted")
                // this.loaded = true;
            },
            updateChartSize() {
                this.chartOptions.chart = {
                    ...this.chartOptions.chart,
                    height: "100%",
                    width: "100%",
                };
            },
            mapLabels: function (name) {

                return name;
            },

        }
    }
</script>