<template2>
    <div class="relative   w-full   flex justify-center items-center p-1">
        <apexchart :id="`apex-${id}`" :ref="`graph-chart-${id}`"
            class="     h-96  w-full  flex items-center  justify-center p-1" :options="chartOptions" :series="series" />
    </div>
</template2>

<template>
    <div class="relative  w-full h-64 flex justify-center items-center px-2  ">
        <apexchart :id="`apex-${id}`" :ref="`graph-chart-${id}`" class="chart h-full w-full h-min-24" type="line"
            @updated="onUpdate()" @mounted="onMounted()" :class="{'opacity-100' : !loaded, 'opacity-100': loaded }"
            :options="chartOptions" :series="series" height="100%" />
    </div>
</template>




<style scoped>
    .chart {
        width: 100%;
        /* Prend toute la largeur du parent */
        height: 100%;
        /* Prend toute la hauteur du parent */
        max-width: 100%;
        /* Empêche le débordement horizontal */
        max-height: 100%;
        /* Empêche le débordement vertical */
        display: block;

        min-height: initial !important;
        /* Évite les comportements indésirables d'affichage */
    }

    .relative {
        position: relative;
        /* width: 100%; Occupe toute la largeur */
        /* height: ; Assure la hauteur complète height: 100%;*/

        /* display: flex;Pour centrer les éléments */
        /* justify-content: center; */
        /* align-items: center; */
        overflow: hidden;
        /* Empêche tout débordement */
        /* Empêche le débordement du contenu */
    }
</style>
<script>

    /*
 

    */

    import VueApexCharts from "vue3-apexcharts";

    export default {
        name: 'LineChart',
        components: {
            apexchart: VueApexCharts,
        },
        props: {
            id: String,
            title: String,
            source: String,
            config: Object,
            records: Object,
            refresh: String,
        },
        data: function () {
            let self = this;
            return {
                labelCounter: 0,
                loaded: false,
                serieTitle: "Température",
                chartOptions: null,
                decimal: null,
                configuration: {
                    chart: {
                        type: "line",
                        stacked: false,
                        zoom: {
                            enabled: false
                        },
                        markers: {
                            hover: {
                                sizeOffset: 5
                            }
                        },
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                                reset: false,
                                customIcons: []
                            },
                            export: {

                            },
                            autoSelected: 'zoom'
                        },

                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ["#FF1654", "#247BA0"],
                    stroke: {
                        width: [2, 2],
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: "20%"
                        }
                    },
                    markers: {
                        hover: {
                            sizeOffset: 5
                        }
                    },
                    xaxis: {
                        //type: 'datetime',
                        //categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
                       // labels: {
                            //rotate: -45,
                            //formatter: (value) => {
                            //    const result = this.labelCounter % 5 === 0 ? value : '';
                           //     this.labelCounter++; // Incrémenter le compteur
                           //     return result;
                           // },
                        //},
                    },
                    yaxis: {
                       
                        labels: {
                            formatter: function (value) {
                                return value.toFixed(2); // Limiter à 2 chiffres après la virgule
                            }
                        }
                        ,
                      //  title: {
                      //      text: "Values (2 decimal places)"
                      //  }
                    }, 
              /*      annotations: {
                        yaxis: [{
                            y: 8, // Limite supérieure de la plage
                            y2: 2, // Limite inférieure de la plage
                            borderColor: '#00E396', // Couleur de la bordure (optionnel)
                            fillColor: 'rgba(0, 227, 150, 0.2)', // Couleur de remplissage semi-transparente
                            opacity: 0.5, // Opacité de la plage
                          //  label: {
                          //      text: "Range 10-20", // Texte à afficher sur la plage
                          //      style: {
                          //          color: "#fff",
                          //          background: "#00E396"
                          //      }
                           // }
                        },
                        {
                            y: 8, // Valeur de la ligne horizontale
                            borderColor: '#FF0000', // Couleur de la ligne
                            label: {
                                text: "Limite (8)", // Texte pour la ligne
                                style: {
                                    color: "#fff",
                                    background: "#FF0000"
                                }
                            }
                        } 
                        ]
                    },*/

                    tooltip: {
                        shared: true,
                        intersect: false,
                        x: {
                            show: false
                        }, 
                        y: {
                            formatter: function (value) {
                                const formattedNumber = new Intl.NumberFormat('fr-FR', {
                  minimumFractionDigits: self.decimal,
                  maximumFractionDigits:self.decimal
                }).format(value);
                                // Personnaliser le format des labels ici
                                return formattedNumber + " " + self.unit; // Par exemple, ajouter un symbole euro
                            }
                        }
                    },


                    
                    legend: {
                        horizontalAlign: "left",
                        offsetX: 40
                    }
                },
                series: [
                    {
                        data: [],
                        name: "Carcasses"
                    }
                ]

            };
        },
        watch: {
            'refresh': function () {
                //  console.log("RowBarChart WATCH records")
                if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
            },
            config: function () {
                // this.config?.source?.title = 
            },
            'records': async function (val) {

                //   let t = this.transformData(val);
                this.unit = this.config?.unit ? this.config?.unit  : "";
                this.decimal = this.config?.decimal ?this.config?.decimal : 0;
                

                if (this.config?.graph?.color != undefined) {
                    this.chartOptions = { colors: ['#FF5733', '#005733'] }
                }
                console.log("val.series----------------------------------------------")
                console.log(JSON.stringify(val.series))
                this.unit = this.config.unit
                this.series = val.series
                //
                //this.series[0] = { name: this.config.name, data: t }
                //  this.series[0] = { name: this.config.name, data: t }
            },

            oldrecords: function (val) {
                //   console.log("LINECHART -------")
                //   console.log(JSON.stringify(this.records))

                if (val == undefined) return;
                if (val.length == 0) return;

                this.labelCounter = 0
                let t = this.transformData(val);
                console.log(t)
                this.series[0] = { data: t }


                //let el = this.$refs['graph-chart-' + this.id]
                //el.refresh()
                // if (this.$refs['graph-chart-' + this.id])
                //     this.$refs['graph-chart-' + this.id].refresh();



            }
        },
        async mounted() {
            if (this.config.chart)
                this.chartOptions = this.mergeDeep(this.configuration, this.config.chart);

            else
                this.chartOptions = this.configuration;



            if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })

        },
        methods:
        {
            mergeDeep(target, source) {
                for (const key in source) {
                    if (source[key] && typeof source[key] === 'object' && !Array.isArray(source[key])) {
                        // Si la valeur est un objet, appeler récursivement
                        target[key] = this.mergeDeep(target[key] || {}, source[key]);
                    } else {
                        // Sinon, remplacer la valeur
                        target[key] = source[key];
                    }
                }
                return target;
            },
            onUpdate() {
                //  console.log("onUpdate")
                this.loaded = true;
            },
            onMounted() {
                //   console.log("onMounted")
                this.loaded = false;
            },
            transformData(records) {

                let data = []
                for (let i = 0; i < records.records.length; i++) {


                    data.push({ x: records.records[i].x, y: records.records[i].y })

                }

                return data
            },
            transformDataOld(records) {

                let data = {}

                for (let i = 0; i < records.records.length; i++) {

                    if (data[records.records[i].color] == undefined) {
                        data[records.records[i].color] = []
                    }
                    if (records?.records[i]?.y)
                        if (typeof records.records[i].y == "string") records.records[i].y = records.records[i].y.replaceAll(" ", "")
                    if (records.records[i].y == "Ininterpretable") records.records[i].y = "0"

                    let y = parseFloat(records.records[i].y)
                    let date_x = records.records[i].x.year + "-0" + (records.records[i].x.month + 1) + "-" + records.records[i].x.date

                    data[records.records[i].color].push({
                        //  x: new Date(records.records[i].x),
                        x: new Date(date_x).getTime(),
                        y: y ? y.toFixed(1) : 100
                    })
                }

                data[records.records[0].color].push({
                    //  x: new Date(records.records[i].x),
                    x: new Date("2024-08-21").getTime(),
                    y: 0
                })




                //     console.log(JSON.stringify(data))
                return data
            }
        }
    }
</script>