<template>

  <div :ref="`radialBar-${config.id}`" class="relative  w-full flex justify-center items-center px-2  min-h-36">
    <apexchart class="w-full pt-0" type="radialBar" :options="chartOptions" :series="series" height="100%" width="100%"
      @updated="onUpdate()" @mounted="onMounted()" :class="{'opacity-0' : !loaded, 'opacity-100': loaded }" />
  </div>

</template>


<style scoped>
  .chart {

    width: 100%;
    /* Prend toute la largeur du parent */
    height: 100%;
    /* Prend toute la hauteur du parent */
    max-width: 100%;
    /* Empêche le débordement horizontal */
    max-height: 100%;
    /* Empêche le débordement vertical */
    display: block;

    min-height: initial !important;
    /* Évite les comportements indésirables d'affichage */
  }

  .relative {

    position: relative;
    /* width: 100%; Occupe toute la largeur */
    /* height: ; Assure la hauteur complète */
    height: 100%;
    /* display: flex;Pour centrer les éléments */
    /* justify-content: center; */
    /* align-items: center; */
    overflow: hidden;
    /* Empêche tout débordement */
    /* Empêche le débordement du contenu */

  }
</style>
<script>
  import VueApexCharts from "vue3-apexcharts";

  let total = 0
  export default {
    name: 'PieChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      parent: String,
      title: String,
      records: Object,
      config: Object
    },
    data: function () {
      return {
        loaded: false,

        ratio: 1.0,
        divDimensions: {
          width: 0,
          height: 0, scale: 1.0
        },

        chartOptions: {
          chart: {
            height: "100%",
            type: 'radialBar',
            offsetY: -10
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: '36px',
                  color: undefined,
                  offsetY: 120
                },
                value: {
                  offsetY: 76,
                  fontSize: '26px',
                  color: undefined,
                  formatter: function (val) {
                    return val + "%";
                  }

                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              shadeIntensity: 0.15,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 65, 91]
            },
          },
          stroke: {
            dashArray: 6
          },
          labels: [''],
        },


        chartOptions20: {
          chart: {
            type: 'radialBar',
            height: '100%', // S'assure que le graphique s'adapte à la hauteur de la div
            width: '100%',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true
          },

          legend: {
            position: 'right',
            show: false,
          },
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: '30%',
                background: 'transparent',
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (/*w*/) {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return total
                  }
                }
              },
              barLabels: {
                enabled: true,
                useSeriesColors: true,
                offsetX: -8,
                fontSize: '19px',
                formatter: function (seriesName, opts) {
                  return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + '%'
                },
              },
            }
          },
          grid: {
            padding: {
              bottom: -80
            }
          },
          responsive2: [{
            breakpoint: 480,
            options: {
              chart: {
                height: 400
              },
              legend: {
                position: 'right',
                show: false,
              }
            }
          }]


        },
        series: [],
      };
    },
    watch: {
      'refresh': function () {
        console.log("RadialBarChart WATCH refresh")
        if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      },
      'records': async function (val) {
        console.log("RadialBarChart WATCH records")
        //   console.log(val)
        let r = this.transformData(val);
        //  if (r == undefined) 
        //  r = { data: [12, 125, 855, 22], name: "" }
        console.log(r)
        this.series = r.series;

        total = r.total
        this.chartOptions =
        {
          labels: r.categories
        }
        //     this.series[0].name = r.series[0].name;
      }
    },

    async mounted() {
      this.updateChartSize();
      window.addEventListener("resize", this.updateChartSize);
      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
    },

    beforeUnmount() {
      window.removeEventListener("resize", this.updateChartSize);
    },

    methods: {
      onUpdate() {
        console.log("onUpdate")
        this.loaded = true;
      },
      onMounted() {
        console.log("onMounted")
        // this.loaded = true;
      },
      getTotal() {
        return 1000
      },
      getDivSize() {

        const div = this.$refs["semi-donut-" + this.config.id]; // Référence à la div
        if (div) {
          const rect = div.getBoundingClientRect();
          this.divDimensions = {
            width: rect.width,
            height: rect.height,
            scale: this.chartOptions?.plotOptions?.pie?.customScale
          };
          console.log("Taille de la div:", this.divDimensions);
        }
        //  this.chartOptions.plotOptions.pie.customScale = 1.0
      },
      updateChartSize() {
        this.getDivSize();
      },

      transformData(data) {

        console.log("RadialBar - transformData transformData transformData transformData transformData", data.records)
        console.log(JSON.stringify(data))
        let series = []
        let labels = []
        let total = 0;


        for (let i = 0; i < data.records.length; i++) {
          if (data.records[i]) {
            total = data.records[i].total
            for (let j = 0; j < 1 /*data.records[i].values.length*/; j++) {
              series.push(data.records[i].values[j]['percentage'])
              labels.push(data.records[i].values[j]['name'])
            }
          }

        }

        console.log("PIE - transformData transformData transformData transformData transformData", data.records)
        console.log(JSON.stringify(series))
        return { categories: labels, series: series, total: total }
      }
    },

  }
</script>