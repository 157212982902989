<template>
  <div class="relative  w-full h-64 flex justify-center items-center px-2  ">
    <apexchart class="chart h-full w-full h-min-24" type="bar" @updated="onUpdate()" @mounted="onMounted()"
      :class="{'opacity-0' : !loaded, 'opacity-100': loaded }" :options="chartOptions" :series="series" height="100%" />
  </div>
</template>

<style scoped>
  .chart {
    width: 100%;
    /* Prend toute la largeur du parent */
    height: 100%;
    /* Prend toute la hauteur du parent */
    max-width: 100%;
    /* Empêche le débordement horizontal */
    max-height: 100%;
    /* Empêche le débordement vertical */
    display: block;

    min-height: initial !important;
    /* Évite les comportements indésirables d'affichage */
  }

  .relative {
    position: relative;
    /* width: 100%; Occupe toute la largeur */
    /* height: ; Assure la hauteur complète height: 100%;*/

    /* display: flex;Pour centrer les éléments */
    /* justify-content: center; */
    /* align-items: center; */
    overflow: hidden;
    /* Empêche tout débordement */
    /* Empêche le débordement du contenu */
  }
</style>




<script>
  import VueApexCharts from "vue3-apexcharts";
  export default {
    name: 'RowBarChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      parent: String,
      title: String,
      records: Object,
      config: Object,
      refresh: String
    },
    data: function () {
      return {
        series: [
          {
            name: "Produits",
            data: []
          }],
        loaded: false,
        chartOptions: {
          chart: {
            type: 'bar',
            height: '100%', // S'assure que le graphique s'adapte à la hauteur de la div
            width: '100%',  // S'assure que le graphique prend toute la largeur
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: false,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: false,
                reset: false,
              },
            },
          },
          title: {
            text: this.title,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          responsive: [
            {
              breakpoint: 768, // Point de rupture pour les petits écrans
              options: {
                chart: {
                  height: '300px', // Ajustement pour les petits écrans
                },
              },
            },
          ],
        }
      }
    },
    watch: {
      'refresh': function () {
      //  console.log("RowBarChart WATCH records")
        if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      },

      'records': async function () {
      //  console.log("RowBarChart WATCH records");

        // Données d'exemple
        /*let lrecords = {
          records: [
            { x: 10, y: "Category 1" },
            { x: 20, y: "Category 2" },
            { x: 15, y: "Category 3" },
          ],
        };*/

        let r = this.transformData(this.records);
       // console.log("Transformed Data:", r);

        if (!r || !r.series || r.series.length === 0 || !r.series[0].data) {
       //   console.error("Invalid transformed data", r);
          this.series = [{ name: "test", data: [] }];
          this.chartOptions = { xaxis: { categories: [] } };
          return;
        }

        // Mettre à jour les séries et les catégories
        this.series[0].data = r.series[0].data;

       // console.log("Transformed Data:", r.series[0].data);


        this.chartOptions = {
          ...this.chartOptions,
          xaxis: { categories: r.categories },
        };
      },
    },
    async mounted() {
      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.id })
      window.addEventListener("resize", this.updateChartSize);
    },
    methods: {
      onUpdate() {
        console.log("onUpdate")
        this.loaded = true;
      },
      onMounted() {
        console.log("onMounted")
        // this.loaded = true;
      },
      updateChartSize() {
        this.chartOptions.chart = {
          ...this.chartOptions.chart,
          height: "100%",
          width: "100%",
        };
      },

      transformData(data) {
        if (!data || !Array.isArray(data.records)) {
      //    console.error("Invalid data structure", data);
          return { categories: [], series: [{ name: "test", data: [] }] };
        }

        let series = [];
        let labels = {};

        for (let i = 0; i < data.records.length; i++) {
          if (data.records[i]) {
            series.push(data.records[i].x); // Ajoute les valeurs de `x`
            labels[data.records[i].y] = data.records[i].y; // Ajoute les catégories uniques de `y`
          }
        }

        return {
          categories: Object.keys(labels), // Retourne les catégories
          series: [{ name: "test", data: series }], // Retourne les séries
        };
      }
    }
  }

</script>