<template>
  <div class="w-full h-full  max-h-96  ">



    <div class="flex flex-col">


      <div class="flex flex-row  flex-strech  items-center py-2   " v-for="line in lines" :key="line.id">


        <div class="w-full font-bold text-base items-center h-full text-right   justify-end h-16 px-6">
          {{line.name}}
        </div>

        <div class="relative w-full  flex justify-center items-center px-2  bg-blue-100 "
          :class="{'opacity-0' : !loaded, 'opacity-100': loaded }">
          <apexchart :id="`apex-${id}`" :ref="`graph-chart-${id}`" class="  chart h-full w-full  " type="bar"
            @updated="onUpdate()" @mounted="onMounted()" :options="chartOptions" :series="series" height="35"
            width="100" />
        </div>

      </div>
    </div>
 
  </div>
</template>


<style scoped>
  .chart {
    width: 100%;
    /* Prend toute la largeur du parent */
    height: 100%;
    /* Prend toute la hauteur du parent */
    max-width: 100%;
    /* Empêche le débordement horizontal */
    max-height: 100%;
    /* Empêche le débordement vertical */
    display: block;

    min-height: initial !important;
    /* Évite les comportements indésirables d'affichage */
  }

  .relative {
    position: relative;
    /* width: 100%; Occupe toute la largeur */
    /* height: ; Assure la hauteur complète height: 100%;*/

    /* display: flex;Pour centrer les éléments */
    /* justify-content: center; */
    /* align-items: center; */
    overflow: hidden;
    /* Empêche tout débordement */
    /* Empêche le débordement du contenu */
  }
</style>
<script>

  /*


  */
  //import Handlebars from "handlebars";
  import VueApexCharts from "vue3-apexcharts";

  export default {
    name: 'FigureSparlineListChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      id: String,
      title: String,
      source: String,
      config: Object,
      records: Object,
      refresh: String,
    },
    data: function () {
      return {

        lines: [
          {
            id: 0,
            name: "Sanglier",

          },
          {
            id: 1,
            name: "Cerf",

          }
        ],
        labelCounter: 0,
        loaded: false,
        serieTitle: "Température",
        chartOptions: {

          chart: {
            type: 'bar',
            width: 100,
            height: 35,
            sparkline: {
              enabled: true
            }
          },
          plotOptions: {
            bar: {
              columnWidth: '80%'
            }
          },
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1
            },
          },
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function (/*seriesName*/) {
                  return ''
                }
              }
            },
            marker: {
              show: false
            }
          },
        },
        chartOptions1: {

          chart: {
            type: "area",
            //  height: 35,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            //  curve: 'straight', 
            curve: 'smooth',
            opacity: 0.1,
            width: 5
          },
          fill: {
            opacity: 0.1,
          },
          yaxis: {
            min: 0
          },
          //colors: ['#DCE6EC'],
          colors: ['#B51010'],
          title: {
            text: '',
            offsetX: 0,
            style: {
              fontSize: '24px',
            }
          },
          subtitle: {
            text: '',
            offsetX: 0,
            style: {
              fontSize: '14px',
            }
          }
        },
        series: [
          {
            data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14],
            name: "serie"
          }
        ]

      };
    },
    watch: {
      'refresh': function () {
        //  console.log("RowBarChart WATCH records")
        if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      },
      config: function () {
        // this.config?.source?.title = 
      },
      records: function (val) {









        
        console.log("Sparkline -------")
        console.log(JSON.stringify(val))

    //    if (val == undefined) return;
    //    if (val.records.length == 0) return;

        this.series = [{
          data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14]
        }]
        this.labelCounter = 0
       // let t = this.transformData(val);
       // console.log(t)
        //     this.series[0] = { data: t }
        //     console.log(JSON.stringify(t))
       // const template = Handlebars.compile(this.config.title);
       // let title = (template(val));
       // console.log(title)

        /*
             
        
                this.chartOptions = {
        
                  title:
                  {
                    text: title
                  },
                  subtitle:
                  {
                    text: this.config.subtitle
                  }
                }
                */


      }



    },
    async mounted() {

      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })

    },
    methods:
    {

      onUpdate() {
        //  console.log("onUpdate")
        this.loaded = true;
      },
      onMounted() {
        //   console.log("onMounted")
        this.loaded = false;
      },
      transformData(records) {

        let data = []
        for (let i = 0; i < records.records.length; i++) {


          data.push({ x: records.records[i].x, y: records.records[i].y })

        }

        return data
      },

    }
  }
</script>